import { Module } from 'vuex';
import { api } from '../../plugins/salut-api';
import { JobState, RootState } from '../types';

const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SET_LAST_ERROR = 'SET_LAST_ERROR';
const SET_EDITOR_CHOICES_PAGE_DATA = 'SET_APP_USERS_PAGE_DATA';
const SET_VOLUNTEER_FUNCTIONS = 'SET_VOLUNTEER_FUNCTIONS';

export const JobActionKeys = {
	fetchEditorChoices: 'fetchEditorChoices',
	deleteEditorChoice: 'deleteEditorChoice',
	fetchVolunteerFunctions: 'fetchVolunteerFunctions',
	addEditorChoice: 'addEditorChoice',
	reorderEditorChoice: 'reorderEditorChoice',
};

export const JobGetterKeys = {
	editorChoicesPageData: 'editorChoicesPageData',
	loading: 'loading',
	lastError: 'lastError',
	volunteerFunctions: 'volunteerFunctions',
	availableVolunteerFunctions: 'availableVolunteerFunctions',
};

const store: Module<JobState, RootState> = {
	namespaced: true,

	state: {
		loading: 0,
		lastError: '',
		editorChoicesPageData: [],
		volunteerFunctions: [],
	},

	mutations: {
		[SHOW_LOADING](state) {
			state.loading += 1;
		},

		[HIDE_LOADING](state) {
			state.loading -= 1;
		},

		[SET_LAST_ERROR](state, error) {
			state.lastError = error;
		},

		[SET_EDITOR_CHOICES_PAGE_DATA](state, result) {
			state.editorChoicesPageData = result;
		},

		[SET_VOLUNTEER_FUNCTIONS](state, volunteerFunctions) {
			state.volunteerFunctions = volunteerFunctions;
		},
	},

	actions: {
		async [JobActionKeys.fetchEditorChoices]({ commit }) {
			try {
				commit(SHOW_LOADING);

				const data = await api.fetchEditorChoiceList();

				commit(SET_EDITOR_CHOICES_PAGE_DATA, data);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [JobActionKeys.deleteEditorChoice]({ commit }, jobId: string) {
			try {
				await api.deleteEditorChoice(jobId);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			}
		},

		async [JobActionKeys.fetchVolunteerFunctions]({ state, commit }) {
			if (!state.volunteerFunctions?.length) {
				try {
					commit(SHOW_LOADING);
					commit(SET_VOLUNTEER_FUNCTIONS, await api.fetchVolunteerFunctions());
				} catch (error) {
					commit(SET_LAST_ERROR, error);
				} finally {
					commit(HIDE_LOADING);
				}
			}
		},

		async [JobActionKeys.addEditorChoice]({ commit }, jobId: string) {
			try {
				const data = await api.addEditorChoice(jobId);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			}
		},

		async [JobActionKeys.reorderEditorChoice]({ commit }, jobIds: string[]) {
			try {
				commit(SHOW_LOADING);
				const data = await api.reorderEditorChoices(jobIds);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},
	},

	getters: {
		[JobGetterKeys.editorChoicesPageData]: state => state.editorChoicesPageData,
		[JobGetterKeys.loading]: state => Boolean(state.loading),
		[JobGetterKeys.lastError]: state => state.lastError,
		[JobGetterKeys.volunteerFunctions]: state => state.volunteerFunctions,
		[JobGetterKeys.availableVolunteerFunctions]: state =>
			state.volunteerFunctions.filter(item => !item.deletedAt),
	},
};

export default store;
