import Vue from 'vue';
import Component from 'vue-class-component';
import { ButtonMixin } from './button-mixins';

@Component
export class SpaceMixin extends Vue {
	// toolbarAttrs = {
	// 	height: '30',
	// 	color: 'transparent',
	// 	flat: true,
	// 	class: 'mt-5 mb-1',
	// };

	// subtitleAttrs = {
	// 	class: 'body-text mono700--text mb-10',
	// };

	// for bottom action buttons
	buttonPadding = {
		/* TODO: this overwrite class in primaryButtonAttrs, quick fix like this */
		class: ButtonMixin.PrimaryButtonAttrsClass + ' ml-4',
	};

	// contactCardSpacingAttrs = {
	// 	class: 'mx-6',
	// };

	// contactCardPaddingAttrs = {
	// 	class: 'px-6',
	// };

	// contactRowAttrs = {
	// 	class: 'mx-n2',
	// };
}
