import { Module } from 'vuex';
import { RootState, UnitState } from '../types';
import { api } from '../../plugins/salut-api';
import { Center, TableItemsOptions } from '../../types';

const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SET_LAST_ERROR = 'SET_LAST_ERROR';
const SET_UNIT = 'SET_UNIT';
const SET_CAUSES = 'SET_CAUSES';
const SET_RECIPIENTS = 'SET_RECIPIENTS';
const SET_SDGOALS = 'SET_SDGOALS';
const SET_UNITS_INFO = 'SET_UNITS_INFO';

export const UnitActionKeys = {
	fetch: 'fetch',
	fetchCauses: 'fetchCauses',
	fetchRecipients: 'fetchRecipients',
	fetchSdgoals: 'fetchSdgoals',
	fetchUnitInfo: 'fetchUnitInfo',
};

export const UnitGetterKeys = {
	loading: 'loading',
	lastError: 'lastError',
	units: 'units',
	causes: 'causes',
	recipients: 'recipients',
	sdgoals: 'sdgoals',
	availableCauses: 'availableCauses',
	availableRecipients: 'availableRecipients',
	availableSdgoals: 'availableSdgoals',
	unitsInfo: 'unitsInfo',
};

// Better name would be NGO - Units, Roles, Causes, Recipients, Sdgoals
const initialState: UnitState = {
	loading: 0,
	lastError: '',
	centers: [],
	causes: [],
	recipients: [],
	sdgoals: [],
	unitsInfo: { result: [], total: 0 },
};

const store: Module<UnitState, RootState> = {
	namespaced: true,

	state: { ...initialState },

	mutations: {
		[SHOW_LOADING](state) {
			state.loading += 1;
		},

		[HIDE_LOADING](state) {
			state.loading -= 1;
		},

		[SET_LAST_ERROR](state, error) {
			state.lastError = error;
		},

		[SET_UNIT](state, data) {
			state.centers = data;

			const isHQ = (c: Center): boolean => c.code.toUpperCase() === 'HQ';

			state.centers?.sort((a, b) => {
				if (isHQ(a) && isHQ(b)) return 0;
				if (isHQ(a) || isHQ(b)) {
					return isHQ(a) ? -1 : 1;
				}
				return a.code < b.code ? -1 : 1;
			});
		},

		[SET_UNITS_INFO](state, data) {
			state.unitsInfo = data;
		},

		[SET_CAUSES](state, causes) {
			state.causes = causes;
		},

		[SET_RECIPIENTS](state, recipients) {
			state.recipients = recipients;
		},

		[SET_SDGOALS](state, sdgoals) {
			state.sdgoals = sdgoals;
		},
	},

	actions: {
		async [UnitActionKeys.fetch]({ commit }, { ngoId }: { ngoId: number }) {
			try {
				commit(SHOW_LOADING);
				const data = await api.fetchCenters(ngoId);
				commit(SET_UNIT, data);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [UnitActionKeys.fetchUnitInfo](
			{ commit },
			{
				ngoId,
				options,
				publicOnly,
			}: { ngoId: number; options?: TableItemsOptions; publicOnly?: boolean },
		) {
			try {
				commit(SHOW_LOADING);

				const { result, total } = await api.fetchCenters(
					ngoId,
					options,
					publicOnly,
				);
				commit(SET_UNITS_INFO, { result, total });
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [UnitActionKeys.fetchCauses]({ commit }) {
			try {
				commit(SHOW_LOADING);
				commit(SET_CAUSES, await api.fetchCauses());
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [UnitActionKeys.fetchRecipients]({ commit }) {
			try {
				commit(SHOW_LOADING);
				commit(SET_RECIPIENTS, await api.fetchRecipients());
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [UnitActionKeys.fetchSdgoals]({ commit }) {
			try {
				commit(SHOW_LOADING);
				commit(SET_SDGOALS, await api.fetchSdgoals());
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},
	},

	getters: {
		[UnitGetterKeys.loading]: state => Boolean(state.loading),
		[UnitGetterKeys.lastError]: state => state.lastError,
		[UnitGetterKeys.units]: state => state.centers,
		[UnitGetterKeys.causes]: state => state.causes,
		[UnitGetterKeys.recipients]: state => state.recipients,
		[UnitGetterKeys.sdgoals]: state => state.sdgoals,
		[UnitGetterKeys.availableCauses]: state =>
			state.causes?.filter(item => !item.deletedAt),
		[UnitGetterKeys.availableRecipients]: state =>
			state.recipients?.filter(item => !item.deletedAt),
		[UnitGetterKeys.availableSdgoals]: state =>
			state.sdgoals?.filter(item => !item.deletedAt),
		[UnitGetterKeys.unitsInfo]: state => state.unitsInfo,
	},
};

export default store;
