import _Vue, { PluginFunction } from 'vue';
import { MultiLangText } from '../types';
import VueI18n, {
	I18nOptions,
	LocaleMessages,
	TranslateResult,
} from 'vue-i18n';

export const CustomI18nPlugin: PluginFunction<I18nOptions> = (
	Vue: typeof _Vue,
	options?: I18nOptions,
) => {
	Vue.prototype.$ct = function(
		texts: MultiLangText | undefined | null | string,
	): TranslateResult {
		if (typeof texts == 'string') {
			const text = texts;
			texts = {
				en: text,
				zhHant: text,
				zhHans: text,
			};
		}
		if (
			!texts ||
			!Object.keys(texts).length ||
			!Object.values(texts).filter(v => v).length
		) {
			return '';
		}
		/**
		 * prepare messages format for i18n lib
		 */
		const messages = Object.entries(texts).reduce(
			(acc: LocaleMessages, [locale, value]): LocaleMessages => {
				if (value) {
					acc[locale] = { v: value };
				}
				return acc;
			},
			{},
		);

		/**
		 * reuse the i18n lib
		 */
		const _i18n = new VueI18n({
			...options,
			locale: this.$i18n.locale,
			messages,
		});
		return _i18n.t('v');
	};
};
