import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from '../store';
import { AuthActionKeys } from '../store/modules/auth';
import { LOGOUT_API_PATH, REFRESH_TOKEN_API_PATH } from '../lib/salut-api';

Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_DOMAIN;

const publicApiPaths = [REFRESH_TOKEN_API_PATH];

const loginApiPaths = ['/admin/login', '/admin/verify', '/admin/logout'];

Vue.axios.interceptors.request.use(
	config => {
		if (
			config.url &&
			// our own API call utilizes config.baseURL and won't pass full address to config.url;
			// If match, meaning we're requesting to external domains (like GCP storage), thus skip the internal auth header
			(/^https?:\/\/.+/.test(config.url) ||
				// no need to include auth header for public API endpoints
				publicApiPaths.includes(config.url))
		) {
			return config;
		}
		// add access token to header;
		// DO NOT use common header here coz it somehow give get obsolete access token (cached?)
		const accessToken = store.getters['auth/accessToken'];
		if (accessToken) {
			config.headers['Authorization'] = `Bearer ${accessToken}`;
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	},
);

axios.interceptors.response.use(undefined, async err => {
	const request = err.config;
	const response = err.response;

	let showAlert = true;

	if (response?.status === 401) {
		if (request.url === REFRESH_TOKEN_API_PATH) {
			// error messages returned by this API is meaningless to user, thus do not show
			showAlert = false;
			// error getting new token. Refresh token should be invalid. Do not retry
			store.dispatch(`auth/${AuthActionKeys.setSessionExpired}`);
			return err;
		} else if (loginApiPaths.includes(request.url)) {
			// do not retry for login path 401 failure
		} else if (publicApiPaths.includes(request.url)) {
			// do not retry for public path 401 failure
		} else if (!request._retry) {
			request._retry = true;

			/**
			 * Just make sure the action is finished successfully, but no need to check inside the token,
			 * coz the new request will later pass the interceptor above and get new token injected
			 */
			const token = await store.dispatch(`auth/${AuthActionKeys.getNewToken}`);
			if (token) {
				return axios(request);
			}
		} else if (request.url === LOGOUT_API_PATH) {
			// assume account is invalid. Fail silently
			return;
		}
	}

	if (showAlert) {
		if (response?.data?.message instanceof Array) {
			store.dispatch('alert/error', response?.data?.message);
		} else {
			store.dispatch(
				'alert/error',
				response?.data?.message || response?.statusText || err,
			);
		}
	}
	// TODO: do i18n for "Network Error" when server down/no network
	throw err.response?.data || err; // should throw or else caller cannot detect problem
});
