import {
	AppBanner,
	AppBannerDto,
	Category,
	CategoryDto,
} from './../../types/index';
import { Module } from 'vuex';
import { api } from '../../plugins/salut-api';
import { LocaleCode, TableItemsOptions } from '../../types';
import { RootState, AppState } from '../types';

const SET_URL_QUERY_LANG = 'SET_URL_QUERY_LANG';
const SET_PREFERENCE_LANG = 'SET_PREFERENCE_LANG';
const CLEAR_URL_QUERY_LANG = 'CLEAR_URL_QUERY_LANG';
const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SET_LAST_ERROR = 'SET_LAST_ERROR';
const SET_APP_BANNER_LIST = 'SET_APP_BANNER_LIST';
const SET_CURRENT_APP_BANNER = 'SET_CURRENT_APP_BANNER';
const CLEAR_CURRENT_APP_BANNER = 'CLEAR_CURRENT_APP_BANNER';
const SET_CATEGORY_SETTING = 'SET_CATEGORY_SETTING';
const SET_DISPLAYED_CATEGORIES = 'SET_DISPLAYED_CATEGORIES';
const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';
const CLEAR_CURRENT_CATEGORY = 'CLEAR_CURRENT_CATEGORY';

function emptyCategoryFactory(): Category {
	return {
		id: '',
		status: false,
		name: '',
		iconUrl: '',
		title: {},
		description: {},
		pageTitle: {},
		metaDescription: {},
		pageBannerDesktop: '',
		pageBannerMobile: '',
		filter: {
			ageRange: { start: 0, end: 100 },
			expectedCommitHoursRange: { start: 0, end: 100 },
		},
	};
}

export const AppActionKeys = {
	setUrlQueryLang: 'setUrlQueryLang',
	setPreferenceLang: 'setPreferenceLang',
	clearUrlQueryLang: 'clearUrlQueryLang',
	fetchAppBannerList: 'fetchAppBannerList',
	reorderAppBanners: 'reorderAppBanners',
	createAppBanner: 'createAppBanner',
	updateAppBanner: 'updateAppBanner',
	deleteAppBanner: 'deleteAppBanner',
	setCurrentAppBanner: 'setCurrentAppBanner',
	clearCurrentAppBanner: 'clearCurrentAppBanner',
	fetchCategorySetting: 'fetchCategorySetting',
	reorderCategories: 'reorderCategories',
	createCategory: 'createCategory',
	updateCategory: 'updateCategory',
	deleteCategory: 'deleteCategory',
	setCurrentCategory: 'setCurrentCategory',
	clearCurrentCategory: 'clearCurrentCategory',
};

export const AppGetterKeys = {
	lang: 'lang',
	preferenceLang: 'preferenceLang',
	urlQueryLang: 'urlQueryLang',
	loading: 'loading',
	lastError: 'lastError',
	appBannerList: 'appBannerList',
	currentAppBanner: 'currentAppBanner',
	categorySetting: 'categorySetting',
	currentCategory: 'currentCategory',
	displayedCategories: 'displayedCategories',
};

const store: Module<AppState, RootState> = {
	namespaced: true,

	state: {
		urlQueryLang: undefined,
		preferenceLang: undefined,
		appBanners: [],
		currentAppBanner: null,
		categories: [],
		currentCategory: emptyCategoryFactory(),
		loading: 0,
		lastError: '',
		displayedCategories: 0,
	},

	actions: {
		[AppActionKeys.setUrlQueryLang]({ commit }, { lang }) {
			commit(SET_URL_QUERY_LANG, lang);
		},

		[AppActionKeys.setPreferenceLang]({ commit }, { lang }) {
			commit(SET_PREFERENCE_LANG, lang);
		},

		[AppActionKeys.clearUrlQueryLang]({ commit }) {
			commit(CLEAR_URL_QUERY_LANG);
		},

		[AppActionKeys.setCurrentAppBanner](
			{ commit },
			{ appBanner }: { appBanner: AppBanner },
		) {
			commit(SET_CURRENT_APP_BANNER, {
				...appBanner,
				// to meet BE class validator, need to remove keys with empty values
				jobId: appBanner.jobId || null,
				eventId: appBanner.eventId || null,
			} as AppBanner);
		},

		[AppActionKeys.clearCurrentAppBanner]({ commit }) {
			commit(CLEAR_CURRENT_APP_BANNER);
		},

		async [AppActionKeys.fetchAppBannerList]({ commit }) {
			try {
				commit(SHOW_LOADING);
				commit(SET_APP_BANNER_LIST, await api.fetchAppBannerItems());
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [AppActionKeys.reorderAppBanners](
			{ commit },
			{ ids }: { ids: string[] },
		) {
			try {
				commit(SHOW_LOADING);
				const appBanner = await api.reorderAppBannerItems(ids);
				commit(SET_CURRENT_APP_BANNER, appBanner);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [AppActionKeys.createAppBanner](
			{ commit },
			{ appBannerDto }: { appBannerDto: AppBannerDto },
		) {
			try {
				commit(SHOW_LOADING);
				const appBanner = await api.createAppBannerItem(appBannerDto);
				commit(SET_CURRENT_APP_BANNER, appBanner);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [AppActionKeys.updateAppBanner](
			{ commit },
			{ id, appBannerDto }: { id: string; appBannerDto: AppBannerDto },
		) {
			try {
				commit(SHOW_LOADING);
				const appBanner = await api.updateAppBannerItem(id, appBannerDto);
				commit(SET_CURRENT_APP_BANNER, appBanner);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [AppActionKeys.deleteAppBanner]({ commit }, { id }: { id: string }) {
			try {
				commit(SHOW_LOADING);
				const appBanner = await api.deleteAppBannerItem(id);
				commit(SET_CURRENT_APP_BANNER, appBanner);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [AppActionKeys.deleteCategory]({ commit }, { id }: { id: string }) {
			try {
				commit(SHOW_LOADING);
				const category = await api.deleteCategoryItem(id);
				commit(SET_CURRENT_CATEGORY, category);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},
		[AppActionKeys.setCurrentCategory](
			{ commit },
			{ category }: { category: Category },
		) {
			commit(SET_CURRENT_CATEGORY, {
				...category,
			} as Category);
		},

		[AppActionKeys.clearCurrentCategory]({ commit }) {
			commit(CLEAR_CURRENT_CATEGORY);
		},

		async [AppActionKeys.fetchCategorySetting]({ commit }) {
			try {
				commit(SHOW_LOADING);
				const categorySetting = await api.fetchCategoryItems();
				commit(SET_CATEGORY_SETTING, categorySetting);
				commit(
					SET_DISPLAYED_CATEGORIES,
					categorySetting.filter(
						(category: { status: boolean }) => category.status === true,
					).length,
				);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [AppActionKeys.reorderCategories](
			{ commit },
			{ ids }: { ids: string[] },
		) {
			try {
				commit(SHOW_LOADING);
				const category = await api.reorderCategoryItems(ids);
				commit(SET_CURRENT_CATEGORY, category);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [AppActionKeys.createCategory](
			{ commit },
			{ categoryDto }: { categoryDto: CategoryDto },
		) {
			try {
				commit(SHOW_LOADING);
				const category = await api.createCategoryItem(categoryDto);
				commit(SET_CURRENT_CATEGORY, category);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [AppActionKeys.updateCategory](
			{ commit },
			{ id, categoryDto }: { id: string; categoryDto: CategoryDto },
		) {
			try {
				commit(SHOW_LOADING);
				const category = await api.updateCategoryItem(id, categoryDto);
				commit(SET_CURRENT_CATEGORY, category);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},
	},

	mutations: {
		[SET_URL_QUERY_LANG](state, lang) {
			state.urlQueryLang = lang;
		},

		[SET_PREFERENCE_LANG](state, lang) {
			state.preferenceLang = lang;
		},

		[CLEAR_URL_QUERY_LANG](state) {
			state.urlQueryLang = undefined;
		},

		[SET_CURRENT_APP_BANNER](state, appBanner: AppBanner) {
			state.currentAppBanner = appBanner;
		},

		[CLEAR_CURRENT_APP_BANNER](state) {
			state.currentAppBanner = null;
		},

		[SET_APP_BANNER_LIST](state, appBanners: AppBanner[]) {
			state.appBanners = appBanners;
		},

		[SHOW_LOADING](state) {
			state.loading += 1;
		},

		[HIDE_LOADING](state) {
			state.loading -= 1;
		},

		[SET_LAST_ERROR](state, error) {
			state.lastError = error;
		},

		[SET_CURRENT_CATEGORY](state, category: Category) {
			state.currentCategory = category;
		},

		[CLEAR_CURRENT_CATEGORY](state) {
			state.currentCategory = emptyCategoryFactory();
		},

		[SET_CATEGORY_SETTING](state, categories: Category[]) {
			state.categories = categories;
		},

		[SET_DISPLAYED_CATEGORIES](state, displayedCategories: number) {
			state.displayedCategories = displayedCategories;
		},
	},

	getters: {
		[AppGetterKeys.lang]: state =>
			state.urlQueryLang || state.preferenceLang || LocaleCode.EN, // determine which param can override which
		[AppGetterKeys.preferenceLang]: state => state.preferenceLang,
		[AppGetterKeys.urlQueryLang]: state => state.urlQueryLang,

		[AppGetterKeys.loading]: state => Boolean(state.loading),
		[AppGetterKeys.lastError]: state => state.lastError,
		[AppGetterKeys.appBannerList]: state => state.appBanners,
		[AppGetterKeys.currentAppBanner]: state => state.currentAppBanner,
		[AppGetterKeys.categorySetting]: state => state.categories,
		[AppGetterKeys.currentCategory]: state => state.currentCategory,
		[AppGetterKeys.displayedCategories]: state => state.displayedCategories,
	},
};

export default store;
