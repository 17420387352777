




















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class TwoColumn extends Vue {
	@Prop({ type: Boolean, default: false }) autoExpand!: boolean;
}
