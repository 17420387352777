import { Module } from 'vuex';

import { RootState, ProfileState, ProfilePreferences } from '../types';
import { api } from '../../plugins/salut-api';
import { LocaleCode } from '../../types';
import { i18n } from '../../lib';
import cloneDeep from 'lodash/cloneDeep';

const SET_PROFILE = 'SET_PROFILE';
const RESET_STATE = 'RESET_STATE';
const SET_TABLE_COLUMN_SETTINGS = 'SET_TABLE_COLUMN_SETTINGS';
const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SET_LAST_ERROR = 'SET_LAST_ERROR';

export const ProfileActionKeys = {
	fetch: 'fetch',
	update: 'update',
	updateTableColumnSettings: 'updateTableColumnSettings',
	resetState: 'resetState',
};

export const ProfileGetterKeys = {
	loading: 'loading',
	lastError: 'lastError',
	basic: 'basic',
	preferences: 'preferences',
	onboarding: 'onboarding',
	tableColumnSettings: 'tableColumnSettings',
	ngo: 'ngo',
};

const initialState: ProfileState = {
	loading: 0,
	lastError: '',
	basic: {
		ngoID: 0,
		staffID: '',
		userCode: '',
		name: '',
		accessCenters: [],
		roleCode: '',
	},
	preferences: {
		language: LocaleCode.EN,
		dateFormat: 'dd/MM/yyyy',
		twoFA: false,
		saveTableColumnSettings: true,
		calendarFormat: 'standard',
		tableColumnSettings: {},
	},
};

const store: Module<ProfileState, RootState> = {
	namespaced: true,

	state: cloneDeep(initialState),

	actions: {
		async [ProfileActionKeys.fetch]({ commit }) {
			try {
				commit(SHOW_LOADING);

				// FIXME remove mimic delay
				await new Promise(resolve => setTimeout(resolve, 200));
				const { data } = await api.fetchProfile();

				commit(SET_PROFILE, data);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [ProfileActionKeys.update](
			{ commit, dispatch },
			params: Pick<ProfileState, 'preferences'>,
		) {
			try {
				commit(SHOW_LOADING);

				// FIXME remove mimic delay
				await new Promise(resolve => setTimeout(resolve, 200));
				const { data } = await api.updateProfile(params);

				commit(SET_PROFILE, data);
				dispatch(
					'alert/success',
					i18n.t('pages.profile.actions.save.success'),
					{
						root: true,
					},
				);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
				dispatch('alert/error', i18n.t('pages.profile.actions.save.failed'), {
					root: true,
				});
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [ProfileActionKeys.updateTableColumnSettings](
			{ commit, dispatch },
			params: Pick<ProfilePreferences, 'tableColumnSettings'>,
		) {
			try {
				commit(SHOW_LOADING);

				const { data } = await api.updateTableColumnSettings(params);

				commit(SET_TABLE_COLUMN_SETTINGS, data);
				dispatch('alert/success', i18n.t('commonUI.dataTable.pref.success'), {
					root: true,
				});
			} catch (error) {
				commit(SET_LAST_ERROR, error);
				dispatch('alert/error', i18n.t('commonUI.dataTable.pref.failed'), {
					root: true,
				});
			} finally {
				commit(HIDE_LOADING);
			}
		},

		[ProfileActionKeys.resetState]({ commit }) {
			commit(RESET_STATE);
		},
	},

	mutations: {
		[SET_PROFILE](state, profile) {
			state.basic = {
				...state.basic,
				ngoID: profile.ngoId,
				staffID: profile.id,
				userCode: profile.userCode,
				name: profile.name,
				accessCenters: profile.accessCenters,
				roleCode: profile.role?.code || '',
			};

			state.preferences = {
				...state.preferences,

				dateFormat: profile.preference.dateFormat,
				twoFA: profile.preference.twoFA,
				language: profile.preference.language,
				saveTableColumnSettings: profile.preference.saveTableColumnSettings,
				calendarFormat: profile.preference.calendarFormat,

				// override, not merge
				tableColumnSettings: {
					...profile.preference.tableColumnSettings,
				},
			};
		},

		[RESET_STATE](state) {
			Object.assign(state, cloneDeep(initialState));
		},

		[SET_TABLE_COLUMN_SETTINGS](state, tableColumnSettings) {
			state.preferences = {
				...state.preferences,

				tableColumnSettings: {
					...tableColumnSettings,
				},
			};
		},

		[SHOW_LOADING](state) {
			state.loading += 1;
		},

		[HIDE_LOADING](state) {
			state.loading -= 1;
		},

		[SET_LAST_ERROR](state, error) {
			state.lastError = error;
		},
	},

	getters: {
		[ProfileGetterKeys.loading]: state => Boolean(state.loading),
		[ProfileGetterKeys.lastError]: state => state.lastError,
		[ProfileGetterKeys.basic]: state => state.basic,
		[ProfileGetterKeys.preferences]: state => state.preferences,
		[ProfileGetterKeys.tableColumnSettings]: state => (tableCode: string) =>
			(state.preferences.tableColumnSettings[tableCode] ?? []).filter(
				p => p.key,
			),
	},
};

export default store;
