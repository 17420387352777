/* eslint-disable @typescript-eslint/camelcase */
import {
	required,
	email,
	integer,
	numeric,
	max,
	min,
	max_value,
	min_value,
	between,
	is,
	oneOf,
	confirmed,
} from 'vee-validate/dist/rules';
import { extend, configure } from 'vee-validate';
import i18n from '../lib/i18n';
import axios from 'axios';
import { isValid, parse } from 'date-fns';
import store from '../store';
import { ProfileGetterKeys } from '../store/modules/profile';

const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

configure({
	defaultMessage: (field, values) => {
		// TODO: i18n field name
		// values._field_ = i18n.t(`fields.${field}`);

		// change name to use default validation error msg
		if (values._rule_ == 'minValue') {
			values._rule_ = 'min_value';
		}
		if (values._rule_ == 'maxValue') {
			values._rule_ = 'max_value';
		}

		if (values._rule_ == 'dropdownRequired') {
			values._rule_ = 'required';
		}

		values._field_ = i18n.t('validation.field'); // TODO: i18n validation

		return i18n.t(`validation.${values._rule_}`, values).toString();
	},
});

extend('required', required);

extend('integer', integer);

extend('numeric', numeric);

extend('max', max);

extend('min', min);

extend('maxValue', max_value);

extend('minValue', min_value);

extend('between', between);

extend('email', email);

extend('oneOf', oneOf);

extend('is', is);

extend('confirmed', confirmed);

extend('dropdownRequired', {
	params: ['emptyValue'],
	validate(value, { emptyValue }: any) {
		return JSON.stringify(value) !== JSON.stringify(emptyValue);
	},
});

extend('date', {
	validate(value) {
		const dateFormat =
			store.getters[`profile/${ProfileGetterKeys.preferences}`].dateFormat ||
			DEFAULT_DATE_FORMAT;
		let result = true;
		try {
			result = isValid(parse(value, dateFormat, new Date()));
		} catch (e) {
			result = false;
		}
		return result;
	},
});

extend('minChoices', {
	params: ['min'],
	validate(value, { min }: any) {
		return value.length >= min;
	},
});

extend('maxChoices', {
	params: ['max'],
	validate(value, { max }: any) {
		return value.length <= max;
	},
});

extend('unique', {
	params: ['checkingEndpoint', 'previousValue'],
	async validate(value, { checkingEndpoint, previousValue }: any) {
		if (value == previousValue) return true;
		const { data: result } = await axios.get(`${checkingEndpoint}${value}`);
		return !result;
	},
});

const includes = (collection: any[] | string, item: string): boolean => {
	return collection.indexOf(item) !== -1;
};

const testEmpty = (value: string) =>
	includes([null, undefined], value) || !String(value).trim().length;

extend('atLeastOne', {
	params: ['en', 'zhHant', 'zhHans'],
	computesRequired: true,
	async validate(_, { en, zhHant, zhHans }: any) {
		const allEmpty = testEmpty(en) && testEmpty(zhHant) && testEmpty(zhHans);
		return {
			data: {
				required: allEmpty,
			},
			valid: !allEmpty,
		};
	},
});

extend('isUrl', {
	validate(value) {
		let url;

		try {
			url = new URL(value);
		} catch (_) {
			return false;
		}

		// from https://stackoverflow.com/a/5717133/6228451
		const pattern = new RegExp(
			'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$',
			'i',
		); // fragment locator
		return pattern.test(value);
	},
});

extend('uuid', {
	validate(value) {
		const uuidRule = new RegExp(
			/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
		);
		return {
			valid: uuidRule.test(value),
		};
	},
});

extend('mixUpperLowerCase', {
	validate(value) {
		const passwordRule = new RegExp('^(?=.*[a-z])(?=.*[A-Z])');
		return {
			valid: passwordRule.test(value),
		};
	},
});

extend('mixAlphaNumber', {
	validate(value) {
		const passwordRule = new RegExp('^(?=.*[a-zA-Z])(?=.*[0-9])');
		return {
			valid: passwordRule.test(value),
		};
	},
});

extend('ngoCode', {
	validate(value) {
		const ngoCodeRule = new RegExp('^[A-Z]+[A-Z_0-9]*$');
		return {
			valid: ngoCodeRule.test(value),
		};
	},
});

extend('isAvailableOption', {
	params: ['isPublished', 'isDeleted'],
	validate(_, { isPublished, isDeleted }: any) {
		if (isPublished && isDeleted) return true;
		if (isDeleted) return false;
		return true;
	},
});

extend('confirmDeleteString', {
	computesRequired: true,
	validate(value: string) {
		return value === 'DELETE';
	},
});

extend('valueInUse', {
	params: ['existingValues'],
	validate(value: string, { existingValues }: any) {
		return !existingValues.includes(value);
	},
});

extend('urlPath', {
	validate(value: string) {
		const ngoCodeRule = new RegExp('^[a-zA-Z0-9_-]+$');
		return {
			valid: ngoCodeRule.test(value),
		};
	},
});

extend('phoneNumber', {
	validate(value) {
		const phoneNumberRule = new RegExp(/^[0-9+\-()\s]*$/);
		return {
			valid: phoneNumberRule.test(value),
		};
	},
});
