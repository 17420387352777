import { i18n } from '../../lib';
import {
	AuditLog,
	Center,
	LocaleCode,
	Cause,
	UserAccount,
	TableItemsOptions,
	Ngo,
	Recipient,
	Region,
	District,
	SubDistrict,
	HeaderPreference,
	ListType,
	ListItem,
	AppBanner,
	AppUserProfile,
	AvailableLocaleCodes,
	Category,
	Sdgoal,
} from '../../types';
import { FilterData } from '../../types/filter';
import { VolunteerFunction } from '../../types/job';
import { EditorJob, Job } from '../../types/job';

export interface RootState {
	userID: number;
	isMiniDrawer: boolean;
}

export enum AuthStatus {
	loading = 'loading',
	success = 'success',
	error = 'error',
}

export interface AuthState {
	status: AuthStatus | null;
	sessionExpiredDialog: boolean;
	sessionExpiredCheckingPaused: boolean;
	scheduledRefreshTimeoutHandler: NodeJS.Timeout | null;
	accessToken: string | null;
}

export interface ProfileBasic {
	ngoID: number;
	name: string;
	staffID: string;
	userCode: string;
	accessCenters: Center[];
	roleCode: string;
}

export interface ProfilePreferences {
	language: LocaleCode;
	dateFormat: string;
	twoFA: boolean;
	saveTableColumnSettings: boolean;
	calendarFormat: 'standard' | 'custom';
	tableColumnSettings: { [key: string]: HeaderPreference[] };
}

export interface ProfileState {
	loading: number;
	lastError?: string;
	basic: ProfileBasic;
	preferences: ProfilePreferences;
}

export interface AppState {
	urlQueryLang?: LocaleCode;
	preferenceLang?: LocaleCode;
	appBanners: Array<AppBanner>;
	currentAppBanner: AppBanner | null;
	loading: number;
	lastError: string;
	categories: Array<Category>;
	currentCategory: Category;
	displayedCategories: number;
}

export interface AlertState {
	message: AlertMessage | null;
}

export interface AlertMessage {
	text: string | string[];
	type: 'success' | 'error';
}

export interface AuditPageData {
	items: Array<AuditLog>;
	total: number;
}

export interface AuditState {
	pageData: AuditPageData;
	loading: number;
	lastError?: string;
	details?: AuditLog;
}

export interface UserPageData {
	items: Array<UserAccount>;
	total: number;
}

export interface UserState {
	pageData: UserPageData;
	loading: number;
	lastError?: string;
	users?: UserAccount[];
	userEdit?: UserAccount;
}

export interface UnitState {
	loading: number;
	lastError?: string;
	centers?: Center[];
	causes?: Cause[];
	recipients?: Recipient[];
	sdgoals: Sdgoal[];
	unitsInfo?: { result: Center[]; total: number };
}

export interface FilterState {
	loading: number;
	lastError?: string;
	filter: FilterData[];
}

export interface NotificationState {
	lastError?: string;
	notification: { [groupKey: string]: { [itemKey: string]: number } };
	notificationTimer?: number;
}

export interface TableState {
	loading: number;
	tableOptions: { [key: string]: TableItemsOptions };
}

export interface NgoPageData {
	items: Array<Ngo>;
	total: number;
}

export interface NgoState {
	pageData: NgoPageData;
	loading: number;
	lastError?: string;
	currentNgo?: Ngo | null;
}

export interface AdminState {
	usersById: { [id: string]: UserAccount };
	currentUserAccount: UserAccount | null;
}

export interface LocationState {
	regions: Region[];
	districts: District[];
	subDistricts: SubDistrict[];
}

export interface ListPageData {
	items: Array<ListItem>;
	total: number;
}

export interface ListTypeState {
	loading: number;
	lastError?: string;
	pageData: ListPageData;
	currentListType?: ListType | null;
	currentListTypeItem?: ListItem | null;
}

export interface VolunteerState {
	loading: number;
	lastError?: string;
	appUsersPageData: AppUserPageData;
}

export interface AppUserPageData {
	items: Array<AppUserProfile>;
	total: number;
}
export interface JobState {
	loading: number;
	lastError?: string;
	editorChoicesPageData: EditorJob[];
	volunteerFunctions: VolunteerFunction[];
}

export const RegionOptionAllCode = '_ALL';
export const OutsideHongKongCode = '_OUTSIDEHK';

export const regionOptionAllFactory = (): Pick<Region, 'name' | 'code'> => ({
	name: [...AvailableLocaleCodes]
		.map(localeCode => [
			localeCode,
			i18n.t('pages.ngo.profiles.location.allRegion', localeCode).toString(),
		])
		.reduce(
			(result, [localeCode, text]) => ({ ...result, [localeCode]: text }),
			{},
		),
	code: RegionOptionAllCode,
});

export const outsideHongKongFactory = (): Pick<Region, 'name' | 'code'> => ({
	name: [...AvailableLocaleCodes]
		.map(localeCode => [
			localeCode,
			i18n
				.t('pages.ngo.profiles.location.outsideHongKong', localeCode)
				.toString(),
		])
		.reduce(
			(result, [localeCode, text]) => ({ ...result, [localeCode]: text }),
			{},
		),
	code: OutsideHongKongCode,
});
