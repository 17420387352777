import { Module } from 'vuex';
import { RootState, ListTypeState } from '../types';
import { api } from '../../plugins/salut-api';
import { ListType, ListItem, TableItemsOptions } from '../../types';
import { LIST_TYPES } from '../../constants/listType';

const SET_PAGE_DATA = 'SET_PAGE_DATA';
const SET_CURRENT_CONSTANT = 'SET_CURRENT_CONSTANT';
const SET_CURRENT_CONSTANT_ITEM = 'SET_CURRENT_CONSTANT_ITEM';
const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SET_LAST_ERROR = 'SET_LAST_ERROR';

export const ListTypeActionKeys = {
	fetchListTypeItems: 'fetchListTypeItems',
	setCurrentListType: 'setCurrentListType',
	setCurrentListTypeItem: 'setCurrentListTypeItem',
};

export const ListTypeGetterKeys = {
	currentListType: 'currentListType',
	currentListTypeItem: 'currentListTypeItem',
	pageData: 'pageData',
	loading: 'loading',
};

const initialState: ListTypeState = {
	loading: 0,
	lastError: '',
	currentListType: null,
	currentListTypeItem: null,
	pageData: { items: [], total: 0 },
};

const store: Module<ListTypeState, RootState> = {
	namespaced: true,

	state: { ...initialState },

	mutations: {
		[SHOW_LOADING](state) {
			state.loading += 1;
		},

		[HIDE_LOADING](state) {
			state.loading -= 1;
		},

		[SET_LAST_ERROR](state, error) {
			state.lastError = error;
		},

		[SET_PAGE_DATA](state, { result, total }) {
			state.pageData = {
				items: result,
				total: total,
			};
		},

		[SET_CURRENT_CONSTANT](state, constant: ListType) {
			state.currentListType = constant;
		},

		[SET_CURRENT_CONSTANT_ITEM](state, constantItem: ListItem) {
			state.currentListTypeItem = constantItem;
		},
	},

	actions: {
		async [ListTypeActionKeys.fetchListTypeItems](
			{ commit },
			{
				constant,
				options,
			}: {
				constant: ListType;
				options: TableItemsOptions;
			},
		) {
			try {
				commit(SHOW_LOADING);
				const data = await api.fetchConstantItems(constant, options);
				commit(SET_PAGE_DATA, data);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async [ListTypeActionKeys.setCurrentListType](
			{ commit },
			{
				key,
			}: {
				key: string;
			},
		) {
			const constant = LIST_TYPES.find(constant => constant.key == key);
			commit(SET_CURRENT_CONSTANT, constant);
		},

		async [ListTypeActionKeys.setCurrentListTypeItem](
			{ commit },
			{ constantItem },
		) {
			commit(SET_CURRENT_CONSTANT_ITEM, constantItem);
		},
	},

	getters: {
		[ListTypeGetterKeys.loading]: state => Boolean(state.loading),
		[ListTypeGetterKeys.pageData]: state => state.pageData,
		[ListTypeGetterKeys.currentListType]: state => state.currentListType,
		[ListTypeGetterKeys.currentListTypeItem]: state =>
			state.currentListTypeItem,
	},
};

export default store;
