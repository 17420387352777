import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export class InputMixin extends Vue {
	fieldAttrs = {
		outlined: true,
		dense: true,
		hideDetails: 'auto',
	};
}
