import { Module } from 'vuex';
import { RootState, NgoState } from '../types';
import { api } from '../../plugins/salut-api';
import { Ngo, NgoListType, TableItemsOptions } from '../../types';
import { AdminActionKeys } from './admin';

const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SET_LAST_ERROR = 'SET_LAST_ERROR';
const SET_PAGE_DATA = 'SET_PAGE_DATA';
const SET_CURRENT_NGO = 'SET_CURRENT_NGO';

export const NgoActionKeys = {
	updateNgoPublishStatus: 'updateNgoPublishStatus',
	fetchByListType: 'fetchByListType',
	fetchNgoById: 'fetchNgoById',
	updateNgo: 'updateNgo',
};

export const NgoGetterKeys = {
	loading: 'loading',
	lastError: 'lastError',
	pageData: 'pageData',
	currentNgo: 'currentNgo',
};

const initialState: NgoState = {
	pageData: { items: [], total: 0 },
	loading: 0,
	lastError: '',
	currentNgo: null,
};

const store: Module<NgoState, RootState> = {
	namespaced: true,

	state: { ...initialState },

	mutations: {
		[SET_PAGE_DATA](state, { result, total }) {
			state.pageData = {
				items: result,
				total: total,
			};
		},

		[SHOW_LOADING](state) {
			state.loading += 1;
		},

		[HIDE_LOADING](state) {
			state.loading -= 1;
		},

		[SET_LAST_ERROR](state, error) {
			state.lastError = error;
		},

		[SET_CURRENT_NGO](state, ngo: Ngo) {
			state.currentNgo = ngo;
		},
	},

	actions: {
		async fetchByListType(
			{ commit, dispatch },
			{
				options,
				listType,
			}: {
				options: TableItemsOptions;
				listType: NgoListType;
			},
		) {
			try {
				commit(SHOW_LOADING);
				let apiResponse;
				switch (listType) {
					case NgoListType.organisationList:
						apiResponse = await api.fetchOrgListNgoItems(options);
						break;
					case NgoListType.applications:
						apiResponse = await api.fetchApplicationNgoItems(options);
						break;
					case NgoListType.inactive:
						apiResponse = await api.fetchInactiveNgoItems(options);
						break;
					default:
						throw new Error(`Must provide "listType"!`);
				}
				const { result, total } = apiResponse;

				// load user accounts before table renders
				// TODO: a bit hacky. Better handle at BE?
				await dispatch(
					`admin/${AdminActionKeys.fetchUsersByIds}`,
					{ userIds: result.map((ngo: Ngo) => ngo.mainAccountId) },
					{ root: true },
				);
				commit(SET_PAGE_DATA, { result, total });
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async fetchNgoById({ commit }, { ngoId }) {
			try {
				const ngo = await api.fetchNgoById(ngoId);
				commit(SET_CURRENT_NGO, ngo);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			}
		},

		async updateNgo({ commit }, { ngoId, ngo }) {
			try {
				const updatedNgo = await api.updateNgo({ id: ngoId, ngo: ngo });
				commit(SET_CURRENT_NGO, updatedNgo);
				return updatedNgo;
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			}
		},
		async [NgoActionKeys.updateNgoPublishStatus](
			{ commit },
			{
				ngoId,
				isPublished,
				reason,
			}: { ngoId: number; isPublished: boolean; reason?: string },
		) {
			try {
				let updatedNgo = null;
				if (isPublished) {
					updatedNgo = await api.unpublishNgoProfile(ngoId, reason);
				} else {
					updatedNgo = await api.publishNgoProfile(ngoId);
				}
				commit(SET_CURRENT_NGO, updatedNgo);
				return updatedNgo;
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			}
		},
	},

	getters: {
		[NgoGetterKeys.loading]: state => Boolean(state.loading),
		[NgoGetterKeys.lastError]: state => state.lastError,
		[NgoGetterKeys.pageData]: state => state.pageData,
		[NgoGetterKeys.currentNgo]: state => state.currentNgo,
	},
};

export default store;
