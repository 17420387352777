












import { Component, Vue, Watch } from 'vue-property-decorator';
import SnackBar from './components/SnackBar.vue';
import { loadLocaleMessagesAsync } from './lib/i18n';
import { namespace } from 'vuex-class';
import { ProfileActionKeys, ProfileGetterKeys } from './store/modules/profile';

const app = namespace('app');
const auth = namespace('auth');
const alert = namespace('alert');
const profile = namespace('profile');

@Component({
	components: {
		SnackBar,
	},
})
export default class App extends Vue {
	@auth.Getter('isAuthenticated') isAuthenticated!: Function;
	@app.Getter('lang') lang!: string;
	@app.Action('setPreferenceLang') setPreferenceLang!: Function;

	@profile.Getter(ProfileGetterKeys.preferences)
	preferences!: { [k: string]: string | number };

	@profile.Action(ProfileActionKeys.fetch) fetchProfile!: Function;

	@alert.Getter('text')
	alertText: string | string[] | undefined;

	@alert.Getter('type')
	alertType: string | undefined;

	onSnackClosed() {
		this.$store.dispatch('alert/close');
	}

	delayedAlertText: string | string[] | undefined = '';

	@Watch('preferences')
	onPreferencesUpdate() {
		this.setPreferenceLang({ lang: this.preferences?.language });
	}

	@Watch('lang')
	onLangChange() {
		loadLocaleMessagesAsync(this.lang);
	}

	@Watch('alertText')
	alertTextUpdate() {
		// so that ppl can call close() to stop the alert from being shown by the default axios error alert
		setTimeout(() => {
			this.delayedAlertText = this.alertText;
		}, 100);
	}

	created() {
		loadLocaleMessagesAsync(this.lang);
		if (!this.isAuthenticated()) return;
		this.fetchProfile();
	}
}
