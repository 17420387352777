import { Module } from 'vuex';

import { RootState, NotificationState } from '../types';
import { api } from '../../plugins/salut-api';

const SET_NOTIFICATION = 'SET_NOTIFICATION';
const SET_LAST_ERROR = 'SET_LAST_ERROR';
const SET_NOTIFICATION_TIMER = 'SET_NOTIFICATION_TIMER';

export const NotificationActionKeys = {
	fetch: 'fetch',
	setTimer: 'setTimer',
	clearTimer: 'clearTimer',
};

const initialState: NotificationState = {
	notification: {},
	notificationTimer: undefined,
	lastError: '',
};

const store: Module<NotificationState, RootState> = {
	namespaced: true,

	state: { ...initialState },

	actions: {
		async [NotificationActionKeys.fetch]({ commit }) {
			try {
				commit(SET_NOTIFICATION, await api.fetchNotificationBadges());
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			}
		},

		[NotificationActionKeys.setTimer]({ commit, dispatch, rootGetters }) {
			dispatch(NotificationActionKeys.clearTimer);
			commit(
				SET_NOTIFICATION_TIMER,
				setInterval(
					() => {
						if (!rootGetters['auth/isAuthenticated']()) {
							// abort if not authenticated
							return dispatch(NotificationActionKeys.clearTimer);
						}
						dispatch(NotificationActionKeys.fetch);
					},
					5 * 60 * 1000, // 5 mins
				),
			);
		},

		[NotificationActionKeys.clearTimer]({ state, commit }) {
			if (state.notificationTimer) {
				clearInterval(state.notificationTimer);
				commit(SET_NOTIFICATION_TIMER, undefined);
			}
		},
	},

	mutations: {
		[SET_NOTIFICATION](state, notification) {
			state.notification = notification;
		},

		[SET_LAST_ERROR](state, error) {
			state.lastError = error;
		},

		[SET_NOTIFICATION_TIMER](state, timer?: number) {
			state.notificationTimer = timer;
		},
	},

	getters: {
		notification: state => state.notification,

		contactApplications: getters =>
			getters.notification?.contacts?.applications,

		eventApplications: getters => getters.notification?.events?.applications,

		jobApplications: getters => getters.notification?.jobs?.applications,
	},
};

export default store;
