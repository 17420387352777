import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/axios';
import './plugins/google-map';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'remixicon/fonts/remixicon.css';
import './assets/style/main.css';
import i18n from './lib/i18n';
import VuetifyConfirm from 'vuetify-confirm';
import { SalutApiPlugin } from './plugins/salut-api';
import { ability } from './lib/casl';
import { abilitiesPlugin, Can } from '@casl/vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import TwoColumn from './components/common/field/TwoColumn.vue';
import './plugins/vee-validate';
import './plugins/lodash';
import './plugins/vue-constants';
import { StyleMixin } from './mixins/style';

Vue.use(abilitiesPlugin, ability);
Vue.component('Can', Can);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('TwoColumn', TwoColumn);

Vue.use(VuetifyConfirm, { vuetify });

Vue.config.productionTip = false;

Vue.use(SalutApiPlugin);
Vue.mixin(StyleMixin);

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: h => h(App),
}).$mount('#app');
