import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '../store';
import { AuthActionKeys, AuthGetterKeys } from '../store/modules/auth';

Vue.use(VueRouter);

const breadcrumbs: Map<string, any> = new Map([
	['home', { name: 'home', to: '/', i18nKey: 'breadcrumb.home' }],
	[
		'profile',
		{ name: 'profile', to: '/profile', i18nKey: 'breadcrumb.profile' },
	],
	[
		'changePassword',
		{
			name: 'changePassword',
			to: '/change-password',
			i18nKey: 'breadcrumb.changePassword',
		},
	],
	[
		'appUserList',
		{
			name: 'App User List',
			to: '/mobile-app/app-user-accounts',
			i18nKey: 'breadcrumb.appUserList',
		},
	],
	[
		'editorChoiceList',
		{
			name: 'Editor Choice List',
			to: '/mobile-app/editor-choices',
			i18nKey: 'breadcrumb.editorChoiceList',
		},
	],
	[
		'staff',
		{ name: 'staff', to: '/management/staff', i18nKey: 'breadcrumb.ngoStaff' },
	],
	[
		'newStaff',
		{
			name: 'newStaff',
			to: '/management/staff/new',
			i18nKey: 'breadcrumb.ngoNewStaff',
		},
	],
	[
		'editStaff',
		{
			name: 'editStaff',
			to: '/management/staff',
			i18nKey: 'breadcrumb.ngoEditStaff',
		},
	],
	[
		'ngoApproved',
		{
			name: 'Approved NGO List',
			to: '/ngos/approved',
			i18nKey: 'pages.ngo.list.approved.title',
		},
	],
	[
		'ngoApplications',
		{
			name: 'Application NGO List',
			to: '/ngos/applications',
			i18nKey: 'pages.ngo.list.applications.title',
		},
	],
	[
		'ngoInactive',
		{
			name: 'Inactive NGO List',
			to: '/ngos/inactive',
			i18nKey: 'pages.ngo.list.inactive.title',
		},
	],
	[
		'ngoDetails',
		{
			name: 'NGO Details',
			to: '/ngos/approved',
			i18nKey: 'breadcrumb.ngoDetails',
		},
	],
	[
		'ngoUnits',
		{
			name: 'NGO Units',
			i18nKey: 'breadcrumb.ngoCenters',
		},
	],
	[
		'centers',
		{
			name: 'centers',
			i18nKey: 'breadcrumb.ngoCenters',
		},
	],
	[
		'editCenter',
		{
			name: 'editCenter',
			to: '/ngo/centers',
			i18nKey: 'breadcrumb.ngoEditCenter',
		},
	],
	[
		'newCenter',
		{
			name: 'editCenter',
			to: '/ngo/centers',
			i18nKey: 'breadcrumb.ngoNewCenter',
		},
	],
	[
		'auditLogList',
		{
			name: 'Audit Log List',
			to: '/system/audit-logs',
			i18nKey: 'breadcrumb.auditLogs',
		},
	],
	[
		'constants',
		{
			name: 'Constants',
			to: '/system/types',
			i18nKey: 'breadcrumb.constants',
		},
	],
	[
		'constantItems',
		{
			//placeholder, to be filled by Breadcrumbs.vue
			name: 'constantItems',
		},
	],
	[
		'appBannerList',
		{
			name: 'App Banner List',
			to: '/marketing/app-banners',
			i18nKey: 'breadcrumb.appBannerList',
		},
	],
	[
		'newAppBanner',
		{
			name: 'New App Banner',
			i18nKey: 'breadcrumb.newAppBanner',
		},
	],
	[
		'editAppBanner',
		{
			name: 'Edit App Banner',
			i18nKey: 'breadcrumb.editAppBanner',
		},
	],
	[
		'categorySetting',
		{
			name: 'Category Setting',
			to: '/marketing/category-setting',
			i18nKey: 'breadcrumb.categorySetting',
		},
	],
	[
		'newCategory',
		{
			name: 'New Category',
			i18nKey: 'breadcrumb.newCategory',
		},
	],
	[
		'editCategory',
		{
			name: 'Edit Category',
			i18nKey: 'breadcrumb.editCategory',
		},
	],
]);

function generateRoutesByOrgListType(orgListType: string, pathPrefix: string) {
	return [
		{
			path: `${pathPrefix}/:id`,
			name: 'View NGO Details',
			component: () => import('../views/ngos/NgoDetails.vue'),
			meta: {
				breadcrumbs: [
					breadcrumbs.get('home'),
					breadcrumbs.get(orgListType),
					breadcrumbs.get('ngoDetails'),
				],
			},
		},
		{
			path: `${pathPrefix}/:id/profile`,
			name: 'View My Profile',
			component: () => import('../views/setting/NgoView.vue'),
			meta: {
				breadcrumbs: [
					breadcrumbs.get('home'),
					breadcrumbs.get(orgListType),
					breadcrumbs.get('ngoDetails'),
					breadcrumbs.get('ngoProfile'),
				],
				dynamicFrom: 2,
			},
		},
		{
			path: `${pathPrefix}/:id/centers`,
			name: 'View Center',
			component: () => import('../views/setting/CenterList.vue'),
			meta: {
				breadcrumbs: [
					breadcrumbs.get('home'),
					breadcrumbs.get(orgListType),
					breadcrumbs.get('ngoDetails'),
					breadcrumbs.get('ngoUnits'),
				],
				dynamicFrom: 2,
			},
		},
		{
			path: `${pathPrefix}/:id/centers/new/edit`,
			name: 'New Center',
			component: () => import('../views/setting/CenterEdit.vue'),
			meta: {
				breadcrumbs: [
					breadcrumbs.get('home'),
					breadcrumbs.get(orgListType),
					breadcrumbs.get('ngoDetails'),
					breadcrumbs.get('ngoUnits'),
					breadcrumbs.get('newCenter'),
				],
				dynamicFrom: 2,
			},
		},
		{
			path: `${pathPrefix}/:id/centers/:centerId/edit`,
			name: 'Edit Centers',
			component: () => import('../views/setting/CenterEdit.vue'),
			meta: {
				breadcrumbs: [
					breadcrumbs.get('home'),
					breadcrumbs.get(orgListType),
					breadcrumbs.get('ngoDetails'),
					breadcrumbs.get('ngoUnits'),
					breadcrumbs.get('editCenter'),
				],
				dynamicFrom: 2,
			},
		},
	];
}

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/Main.vue'),
		meta: {
			breadcrumbs: [{ name: 'Home' }],
		},
		children: [
			{
				path: '/profile',
				name: 'profile',
				component: () => import('../views/Profile.vue'),
				meta: {
					breadcrumbs: [breadcrumbs.get('home'), breadcrumbs.get('profile')],
				},
			},
			{
				path: '/change-password',
				name: 'changePassword',
				component: () => import('../views/ChangePassword.vue'),
				// beforeEnter: ifNotAuthenticated,
				meta: {
					breadcrumbs: [
						breadcrumbs.get('home'),
						breadcrumbs.get('changePassword'),
					],
				},
			},
			{
				path: '/management',
				name: 'NGO Home',
				component: { render: c => c('router-view') },
				children: [
					{
						path: 'staff',
						name: 'staff',
						component: () => import('../views/setting/StaffAccountList.vue'),
						meta: {
							breadcrumbs: [breadcrumbs.get('home'), breadcrumbs.get('staff')],
						},
					},
					{
						path: 'staff/new',
						name: 'newStaff',
						component: () => import('../views/setting/StaffAccountEdit.vue'),
						meta: {
							breadcrumbs: [
								breadcrumbs.get('home'),
								breadcrumbs.get('staff'),
								breadcrumbs.get('newStaff'),
							],
						},
					},
					{
						path: 'staff/:id',
						name: 'editStaff',
						component: () => import('../views/setting/StaffAccountEdit.vue'),
						props: true,
						meta: {
							breadcrumbs: [
								breadcrumbs.get('home'),
								breadcrumbs.get('staff'),
								breadcrumbs.get('editStaff'),
							],
						},
					},
				],
			},

			{
				path: '/mobile-app',
				name: 'App Management',
				component: { render: c => c('router-view') },
				children: [
					{
						path: 'app-user-accounts',
						name: 'App User List',
						component: () => import('../views/app/AppAccountList.vue'),
						meta: {
							breadcrumbs: [
								breadcrumbs.get('home'),
								breadcrumbs.get('appUserList'),
							],
						},
					},
					{
						path: 'editor-choices',
						name: 'Editor Choices',
						component: () => import('../views/app/EditorChoiceList.vue'),
						meta: {
							breadcrumbs: [
								breadcrumbs.get('home'),
								breadcrumbs.get('editorChoiceList'),
							],
						},
					},
				],
			},

			{
				path: '/ngos',
				name: 'NGOs and Organisations',
				component: { render: c => c('router-view') },
				children: [
					{
						name: 'NGO list approved',
						path: 'approved',
						component: () => import('../views/ngos/NgoApprovedList.vue'),
						meta: {
							breadcrumbs: [
								breadcrumbs.get('home'),
								breadcrumbs.get('ngoApproved'),
							],
						},
					},
					{
						name: 'NGO list applications',
						path: 'applications',
						component: () => import('../views/ngos/NgoApplicationList.vue'),
						meta: {
							breadcrumbs: [
								breadcrumbs.get('home'),
								breadcrumbs.get('ngoApplications'),
							],
						},
					},
					{
						name: 'NGO list inactive',
						path: 'inactive',
						component: () => import('../views/ngos/NgoInactiveList.vue'),
						meta: {
							breadcrumbs: [
								breadcrumbs.get('home'),
								breadcrumbs.get('ngoInactive'),
							],
						},
					},
					{
						path: 'new',
						name: 'NGO creation',
						component: () => import('../views/setting/NewNgo.vue'),
						meta: {
							breadcrumbs: [
								breadcrumbs.get('home'),
								breadcrumbs.get('ngoApplications'),
								{ name: 'New NGO', i18nKey: 'pages.ngo.profiles.title' },
							],
						},
					},
					...generateRoutesByOrgListType('ngoApproved', 'approved'),
					...generateRoutesByOrgListType('ngoApplications', 'applications'),
					...generateRoutesByOrgListType('ngoInactive', 'inactive'),
				],
			},
			{
				path: '/marketing',
				name: 'Marketing',
				component: { render: c => c('router-view') },
				children: [
					{
						path: 'app-banners',
						component: { render: c => c('router-view') },
						children: [
							{
								path: '',
								name: 'App Banner List',
								component: () => import('../views/marketing/AppBannerList.vue'),
								meta: {
									breadcrumbs: [
										breadcrumbs.get('home'),
										breadcrumbs.get('appBannerList'),
									],
								},
							},
							{
								path: 'new',
								name: 'Create App Banner',
								component: () => import('../views/marketing/AppBannerEdit.vue'),
								meta: {
									breadcrumbs: [
										breadcrumbs.get('home'),
										breadcrumbs.get('appBannerList'),
										breadcrumbs.get('newAppBanner'),
									],
								},
							},
							{
								path: 'edit',
								name: 'Edit App Banner',
								component: () => import('../views/marketing/AppBannerEdit.vue'),
								meta: {
									breadcrumbs: [
										breadcrumbs.get('home'),
										breadcrumbs.get('appBannerList'),
										breadcrumbs.get('editAppBanner'),
									],
								},
							},
						],
					},
					{
						path: 'category-setting',
						component: { render: c => c('router-view') },
						children: [
							{
								path: '',
								name: 'Category Setting',
								component: () =>
									import('../views/marketing/CategorySetting.vue'),
								meta: {
									breadcrumbs: [
										breadcrumbs.get('home'),
										breadcrumbs.get('categorySetting'),
									],
								},
							},
							{
								path: 'new',
								name: 'New Category',
								component: () => import('../views/marketing/CategoryEdit.vue'),
								meta: {
									breadcrumbs: [
										breadcrumbs.get('home'),
										breadcrumbs.get('categorySetting'),
										breadcrumbs.get('newCategory'),
									],
								},
							},
							{
								path: 'edit',
								name: 'Edit Category',
								component: () => import('../views/marketing/CategoryEdit.vue'),
								meta: {
									breadcrumbs: [
										breadcrumbs.get('home'),
										breadcrumbs.get('categorySetting'),
										breadcrumbs.get('editCategory'),
									],
								},
							},
						],
					},
				],
			},
			{
				path: '/system',
				name: 'System',
				component: { render: c => c('router-view') },
				children: [
					{
						path: 'types',
						component: { render: c => c('router-view') },
						children: [
							{
								path: '',
								name: 'Constants',
								component: () => import('../views/system/ListType.vue'),
								meta: {
									breadcrumbs: [
										breadcrumbs.get('home'),
										breadcrumbs.get('constants'),
									],
								},
							},
							{
								path: ':key',
								name: 'Config Details',
								component: () => import('../views/system/ListTypeList.vue'),
								meta: {
									breadcrumbs: [
										breadcrumbs.get('home'),
										breadcrumbs.get('constants'),
										breadcrumbs.get('constantItems'),
									],
								},
							},
						],
					},
					{
						path: 'audit-logs',
						component: { render: c => c('router-view') },
						children: [
							{
								path: '',
								name: 'Audit Log List',
								component: () => import('../views/system/AuditLogs.vue'),
								meta: {
									breadcrumbs: [
										breadcrumbs.get('home'),
										breadcrumbs.get('auditLogList'),
									],
								},
							},
							{
								path: ':id',
								name: 'Audit Log Details',
								component: () => import('../views/system/AuditLogDetails.vue'),
								meta: {
									breadcrumbs: [
										breadcrumbs.get('home'),
										breadcrumbs.get('auditLogList'),
										{
											name: 'View Audit Log',
											i18nKey: 'breadcrumb.auditLogDetails',
										},
									],
								},
							},
						],
					},
				],
			},
		],
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/Login.vue'),
		// beforeEnter: ifNotAuthenticated,
	},
	{
		path: '/forget-password',
		name: 'forgetPassword',
		component: () => import('../views/ForgetPassword.vue'),
		// beforeEnter: ifNotAuthenticated,
	},
	{
		path: '/staff-welcome',
		name: 'staffWelcome',
		component: () => import('../views/ResetPassword.vue'),
		props: { mode: 'staff-welcome' },
	},
	{
		path: '/reset-password',
		name: 'resetPassword',
		component: () => import('../views/ResetPassword.vue'),
		props: { mode: 'reset-password' },
	},
	{
		path: '/reset-password-success',
		name: 'resetPasswordSuccess',
		component: () => import('../views/ResetPasswordSuccess.vue'),
	},
	{
		path: '*',
		redirect: '/',
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	const publicPages = [
		'login',
		'forgetPassword',
		'resetPassword',
		'staffWelcome',
		'resetPasswordSuccess',
	];

	/**
	 * global processing
	 */
	// process language query
	if (to.query.lang) {
		store.dispatch('app/setUrlQueryLang', { lang: to.query.lang });
		// remove `lang` from query param and keep others
		next({
			path: to.path,
			query: Object.entries(to.query)
				.filter(([key]) => key !== 'lang')
				.reduce((prev, [key, val]) => ({ ...prev, [key]: val }), {}),
		});
	}

	// dynamically change the "from" route in breadcrumbs
	if (to.meta.dynamicFrom && from.meta.breadcrumbs) {
		const dynaIdx = to.meta.dynamicFrom;
		const fromIdx = from.meta.breadcrumbs.length > dynaIdx ? dynaIdx : 0;

		if (dynaIdx >= 1 && fromIdx >= 1) {
			const breadCrumb = from.meta.breadcrumbs[fromIdx];
			const path =
				fromIdx === from.meta.breadcrumbs.length - 1
					? from.path
					: breadCrumb.to;

			to.meta.breadcrumbs[dynaIdx] = {
				name: breadCrumb.name,
				to: path,
				i18nKey: breadCrumb.i18nKey,
			};
		}
	}

	/**
	 * Authentication related processing
	 */
	const isLoggedIn = store.getters[`auth/${AuthGetterKeys.isAuthenticated}`]();
	if (isLoggedIn) {
		// Check permission
		// this action is sync, and with duplicate calling prevention logic inside
		store.dispatch(`auth/${AuthActionKeys.initAccessTokenFromLocalStorage}`);
		// only check when access token exists because abilities are decoded from the token.
		// otherwise will be handled next by matching against `publicPages`

		// badge update
		if (typeof to.name == 'string' && !publicPages.includes(to.name)) {
			store.dispatch('notification/fetch');
		}
	} else {
		// not logged in. This should be distinquished from session expired
		if (typeof to.name == 'string') {
			if (!publicPages.includes(to.name)) {
				next({ name: 'login', query: to.query });
			}
		} else {
			// special cases when path has no name
			if (to.path === '/') {
				// somehow root path(/) cannot be matched, thus the name is not "home" but null
				// TODO: investigate and fix this (it works in admin portal)
				next({ name: 'login', query: to.query });
			}
		}
	}

	// fallback to default
	next();
});

export default router;
