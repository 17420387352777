import jwtDecode from 'jwt-decode';
import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';
import { AccessTokenDto, UserPermissionRawRule } from '../../types';

export const getRulesFromAccessToken = (accessToken: string) => {
	try {
		const tokenPayload = jwtDecode<AccessTokenDto>(accessToken);
		const payload = tokenPayload.permissions;
		const rules = unpackRules<UserPermissionRawRule>(payload);
		return rules;
	} catch (e) {
		console.error('error parsing rules from access token', e);
		return [];
	}
};

export const ability = new Ability();
