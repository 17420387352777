import Vue from 'vue';

declare module 'vue/types/vue' {
	interface Vue {
		readonly $listFixedColWidth: string;
		readonly $listTitleColWidth: string;
	}
}

Vue.prototype.$listFixedColWidth = '150px';
Vue.prototype.$listTitleColWidth = '200px';
