import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import {
	app,
	auth,
	profile,
	alert,
	audit,
	user,
	unit,
	notification,
	table,
	ngo,
	admin,
	location,
	listType,
	volunteer,
} from './modules';
import axios from 'axios';

import { RootState } from './types';
import { abilityPlugin } from './plugins';
import { ProfileActionKeys } from './modules/profile';
import job from './modules/job';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
	plugins: [abilityPlugin],

	state: {
		isMiniDrawer: false,
		userID: 1,
	},

	mutations: {
		toggleDrawer(state, val) {
			const temp = state.isMiniDrawer;
			if (val) state.isMiniDrawer = val;
			else state.isMiniDrawer = !temp;
			return state;
		},
	},

	actions: {
		async initMain({ dispatch }) {
			dispatch(`profile/${ProfileActionKeys.fetch}`, {}, { root: true });

			// TODO other actions when init app
		},

		saveCustomFields({ dispatch }, newField) {
			axios
				.post(`/form/custom-fields`, newField)
				.then(function() {
					dispatch('loadCustomFields');
				})
				.catch(function(error) {
					console.error('error saving custom fields', error);
				});
		},
		updateCustomFields({ dispatch }, config) {
			axios
				.put(`/form/custom-fields/${config.id}`, config.newField)
				.then(function() {
					dispatch('loadCustomFields');
				})
				.catch(function(error) {
					console.error('error updating custom fields', error);
				});
		},
		resetModuleState({ dispatch }) {
			dispatch('table/resetState');
			dispatch(`profile/${ProfileActionKeys.resetState}`);
		},
	},

	modules: {
		app,
		auth,
		profile,
		alert,
		audit,
		user,
		unit,
		notification,
		table,
		ngo,
		admin,
		location,
		listType,
		volunteer,
		job,
	},
};

export default new Vuex.Store<RootState>(store);
