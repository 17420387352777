import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export class CardMixin extends Vue {
	cardAttrs = {
		flat: true,
		class: 'px-4',
	};

	cardOutlinedAttrs = {
		outlined: true,
		color: 'border',
		class: 'transparent-background', //to override the background-color 'border' set above
	};

	cardActionsAttrs = {
		class: 'px-0 pb-4 mt-10 justify-end',
	};
}
