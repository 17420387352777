import Vue from 'vue';
import VueI18n, { I18nOptions } from 'vue-i18n';
import axios from 'axios';
import { CustomI18nPlugin } from '../plugins/custom-i18n';
import {
	LocaleCode,
	LocaleValidationCode,
	isSupportedLocale,
} from '../types/locale';

const defaultLocale = LocaleCode.EN;
const fallbackLocale = {
	[LocaleCode.EN]: [LocaleCode.TC, LocaleCode.SC],
	[LocaleCode.TC]: [LocaleCode.SC, LocaleCode.EN],
	[LocaleCode.SC]: [LocaleCode.TC, LocaleCode.EN],
};

export const enTextFieldsOrder = [LocaleCode.EN, LocaleCode.TC, LocaleCode.SC];
export const zhHantTextFieldsOrder = [
	LocaleCode.TC,
	LocaleCode.SC,
	LocaleCode.EN,
];
export const zhHansTextFieldsOrder = [
	LocaleCode.SC,
	LocaleCode.TC,
	LocaleCode.EN,
];

export const LocaleToTextFieldMap = {
	[LocaleCode.EN]: enTextFieldsOrder,
	[LocaleCode.TC]: zhHantTextFieldsOrder,
	[LocaleCode.SC]: zhHansTextFieldsOrder,
};

const i18nOptions: I18nOptions = {
	locale: defaultLocale,
	fallbackLocale,
};

Vue.use(VueI18n);
Vue.use(CustomI18nPlugin, i18nOptions); // add $ct for unpacking MultiLangText

const loadedLanguages: string[] = [];
const i18n = new VueI18n(i18nOptions);

function setI18nLanguage(locale: string) {
	i18n.locale = locale;
	axios.defaults.headers.common['Locale'] = locale;
	return locale;
}

export function loadLocaleMessagesAsync(
	locale: string,
	setLocale = true,
): Promise<any> {
	if (!locale) {
		console.warn(`Empty locale: "${locale}"`);
		// it's not a bad error, just resolve coz we already threw a warning.
		return Promise.resolve();
	}
	// If the same language
	if (setLocale && loadedLanguages.length > 0 && i18n.locale === locale) {
		return Promise.resolve(setI18nLanguage(locale));
	}

	// If the language was already loaded
	if (setLocale && loadedLanguages.includes(locale)) {
		return Promise.resolve(setI18nLanguage(locale));
	}
	// for vee-validate import
	let validateCode = '';
	if (isSupportedLocale(locale)) validateCode = LocaleValidationCode[locale];

	// If the language hasn't been loaded yet
	return Promise.all([
		import(`@/locales//${locale}.json`),
		import(`vee-validate/dist/locale/${validateCode}.json`),
	]).then(([messages, validationMessages]) => {
		i18n.setLocaleMessage(locale, {
			...messages.default,
			validation: Object.assign(
				validationMessages.messages,
				messages.default.validation,
			),
		});
		loadedLanguages.push(locale);
		if (setLocale) {
			return Promise.resolve(setI18nLanguage(locale));
		}
	});
}

//because of loading time, when following function is called twice, the setI18nLanguage() calls could be out of order.
//Workaround is to call it once only, hence setting false here
loadLocaleMessagesAsync(defaultLocale, false);

export default i18n;
