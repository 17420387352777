import Vue, { PluginFunction } from 'vue';
import { SalutApiOptions } from '../lib/salut-api';
import { SalutApi } from '../lib/salut-api';

declare module 'vue/types/vue' {
	// 3. Declare augmentation for Vue
	interface Vue {
		$api: SalutApi;
	}

	interface VueConstructor {
		$api: SalutApi;
	}
}

const api = new SalutApi();

const SalutApiPlugin: PluginFunction<SalutApiOptions> = vue => {
	vue.$api = api;
	vue.prototype.$api = api;
};

export { SalutApiPlugin, api };
