import { TableItemsOptions, UserAccount } from './../../types/index';
import { Module } from 'vuex';
import { RootState, UserState } from '../types';
import { api } from '../../plugins/salut-api';

const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SET_LAST_ERROR = 'SET_LAST_ERROR';
const SET_PAGE_DATA = 'SET_PAGE_DATA';
const SET_USER = 'SET_USER';
const SET_EDIT_USER = 'SET_EDIT_USER';

export const UserActionKeys = {
	fetch: 'fetch',
	edit: 'edit',
};

export const UserGetterKeys = {
	loading: 'loading',
	lastError: 'lastError',
	users: 'users',
	userEdit: 'userEdit',
	pageData: 'pageData',
};

const initialState: UserState = {
	pageData: { items: [], total: 0 },
	loading: 0,
	lastError: '',
	users: [],
	userEdit: undefined,
};

const store: Module<UserState, RootState> = {
	namespaced: true,

	state: { ...initialState },

	mutations: {
		[SHOW_LOADING](state) {
			state.loading += 1;
		},

		[HIDE_LOADING](state) {
			state.loading -= 1;
		},

		[SET_LAST_ERROR](state, error) {
			state.lastError = error;
		},

		[SET_PAGE_DATA](state, { result, total }) {
			state.pageData = {
				items: result,
				total: total,
			};
		},

		[SET_USER](state, data) {
			state.users = data;
		},

		[SET_EDIT_USER](state, data) {
			state.userEdit = data;
		},
	},

	actions: {
		async [UserActionKeys.fetch]({ commit }, options: TableItemsOptions) {
			try {
				commit(SHOW_LOADING);

				const data = await api.fetchUserItems(options);

				commit(SET_PAGE_DATA, data);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},
		async [UserActionKeys.edit]({ commit }, { user }: { user: UserAccount }) {
			commit(SET_EDIT_USER, user);
		},
	},

	getters: {
		[UserGetterKeys.loading]: state => Boolean(state.loading),
		[UserGetterKeys.lastError]: state => state.lastError,
		[UserGetterKeys.users]: state => state.users,
		[UserGetterKeys.userEdit]: state => state.userEdit,
		[UserGetterKeys.pageData]: state => state.pageData,
	},
};

export default store;
