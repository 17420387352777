import Vue from 'vue';
import Component from 'vue-class-component';

export const CHIP = {
	class: 'rounded-pill px-4 py-2',
	style: 'justify-content: center;',
};

// v1: POSITIVE_PRIOR_CHIP & POSITIVE_PRIOR_LABEL_CHIP
export const ACTIVE_CHIP = {
	...CHIP,
	dark: true,
	color: 'kimberly',
};

// v1: POSITIVE_AFTER_CHIP & POSITIVE_AFTER_LABEL_CHIP
export const INACTIVE_CHIP = {
	...CHIP,
	dark: true,
	color: 'offWhite',
	textColor: 'mono500',
};

// v1: NEUTRAL_CHIP & NEUTRAL_LABEL_CHIP
export const NEUTRAL_CHIP = {
	...CHIP,
	outlined: true,
	dark: false,
	color: 'mono500',
};

// v1: NEGATIVE_CHIP & NEGATIVE_LABEL_CHIP
export const NEGATIVE_CHIP = {
	...CHIP,
	outlined: true,
	dark: false,
	color: 'error',
};

// v1: ITEM_CHIP
export const MULTI_CHIP = {
	...CHIP,
	dark: true,
	textColor: 'mono800',
	color: 'offWhite',
};

// v1: ITEM_CHIP
export const VIEW_CHIP = {
	...CHIP,
	outlined: true,
	dark: false,
	color: 'primary darken-1',
};

@Component
export class ChipMixin extends Vue {
	ACTIVE_CHIP = ACTIVE_CHIP;

	INACTIVE_CHIP = INACTIVE_CHIP;

	NEGATIVE_CHIP = NEGATIVE_CHIP;

	NEUTRAL_CHIP = NEUTRAL_CHIP;

	MULTI_CHIP = MULTI_CHIP;

	VIEW_CHIP = VIEW_CHIP;
}
