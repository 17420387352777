import axios from 'axios';
import {
	ListType,
	District,
	MultiLangText,
	Ngo,
	NgoApprovalStatus,
	Region,
	SubDistrict,
	TableItemsOptions,
	AppBannerDto,
	AppBanner,
	AppUserProfile,
	CategoryDto,
	Category,
} from '../types';
import { tableItemUrl } from '../lib/customisableTable';
import { Job, JobStatusCode, VolunteerFunction } from '../types/job';

export interface SalutApiOptions {
	apiUrl: string;
}

export const REFRESH_TOKEN_API_PATH = '/admin/login/refresh-access-token';
export const LOGOUT_FORCE_API_PATH = '/admin/logout/force';
export const LOGOUT_API_PATH = '/admin/logout';
export class SalutApi {
	DATA_LENGTH_LIMIT = 500;

	async checkLoginEmailExists(email: string) {
		const { data } = await axios.get(`/admin/check-email-exist/${email}`);
		return data;
	}

	async checkResetPasswordCode(code: string) {
		const { data } = await axios.get(`/admin/reset-password/${code}`);
		return data;
	}

	async requestResetPassword(email: string) {
		const { data } = await axios.post(`/admin/reset-password/`, {
			email,
		});
		return data;
	}

	async resetPassword(code: string, newPassword: string) {
		const { data } = await axios.put(`/admin/reset-password/${code}`, {
			newPassword,
		});
		return data;
	}

	async suspendUser(id: string, suspend: boolean): Promise<any> {
		const body = {
			id: id,
			suspend: suspend,
		};
		const { data } = await axios.post(`/admin/suspend-user/`, body);
		return data;
	}

	async getNewAccessToken(refreshToken: string): Promise<string> {
		try {
			const {
				data: { accessToken },
			} = await axios.post(REFRESH_TOKEN_API_PATH, { refreshToken });
			return accessToken;
		} catch (e) {
			console.warn(e); // not a big problem
			return '';
		}
	}

	async fetchProfile(): Promise<any> {
		const { data } = await axios.get('/admin/me');
		return data;
	}

	async updateProfile(params: any): Promise<any> {
		const { data } = await axios.patch('/admin/me', params);
		return data;
	}

	async updateTableColumnSettings(params: any): Promise<any> {
		const { data } = await axios.patch('/admin/table-column-settings', params);
		return data;
	}

	async fetchTableFilters(code: string): Promise<any> {
		const { data } = await axios.get(`/admin/filters?tableName=${code}`);
		return data;
	}

	async fetchOneFilter(id: number): Promise<any> {
		const { data } = await axios.get(`/admin/filters/${id}`);
		return data;
	}

	async deletOneFilter(id: number): Promise<any> {
		const { data } = await axios.delete(`/admin/filters/${id}`);
		return data;
	}

	async createFilter(params: any): Promise<any> {
		const { data } = await axios.post('/admin/filters', params);
		return data;
	}

	async updateFilter(params: any): Promise<any> {
		const { data } = await axios.put(`/admin/filters/${params.id}`, {
			name: params.name,
			data: params.data,
		});
		return data;
	}

	async updateFilterParams(filterId: number, params: any): Promise<any> {
		const { data } = await axios.put(`/admin/filters/${filterId}/params`, {
			data: params,
		});
		return data;
	}

	async fetchTableItems(params: TableItemsOptions): Promise<any> {
		const { data } = await axios.get(tableItemUrl(params));
		return data;
	}

	async fetchOrgListNgoItems(options: TableItemsOptions): Promise<any> {
		const url = `/contact/ngos?approvalStatuses[]=${NgoApprovalStatus.approved}`;
		const data = this.fetchTableItems({ ...options, url: url });
		return data;
	}

	async fetchApplicationNgoItems(options: TableItemsOptions): Promise<any> {
		const url = `/contact/ngos?approvalStatuses[]=${NgoApprovalStatus.pending}`;
		const data = this.fetchTableItems({ ...options, url: url });
		return data;
	}

	async fetchInactiveNgoItems(options: TableItemsOptions): Promise<any> {
		const url = `/contact/ngos?approvalStatuses[]=${NgoApprovalStatus.rejected}&approvalStatuses[]=${NgoApprovalStatus.suspended}`;
		const data = this.fetchTableItems({ ...options, url: url });
		return data;
	}

	async fetchEditorChoiceList(): Promise<Job[]> {
		const { data } = await axios.get('/job/editor-choices');
		return data;
	}

	async addEditorChoice(jobId: string): Promise<Job> {
		const { data } = await axios.post(`/job/editor-choices/${jobId}`);
		return data;
	}

	async deleteEditorChoice(jobId: string): Promise<Job> {
		const { data } = await axios.delete(`/job/editor-choices/${jobId}`);
		return data;
	}

	async reorderEditorChoices(jobIds: string[]) {
		const { data } = await axios.post(`/job/editor-choices/reorder`, jobIds);
		return data;
	}

	async fetchJob(id: string): Promise<any> {
		const { data } = await axios.get(
			`/job/jobs/${id}?getNgoName=true&forPublic=true`,
		);
		return data;
	}

	async fetchJobs(
		options: TableItemsOptions,
		statuses: JobStatusCode[],
		onGoingJob?: boolean,
	): Promise<any> {
		const baseUrl = '/job/jobs';
		const params = new URLSearchParams();

		statuses.forEach(s => {
			params.append('statuses', s);
		});

		if (onGoingJob) {
			params.append('onGoingJob', 'true');
			params.append('forPublic', 'true');
		}

		const data = await this.fetchTableItems({
			...options,
			url: `${baseUrl}?${params.toString()}`,
		});
		return data;
	}

	async fetchAppUserItems(
		params: TableItemsOptions,
	): Promise<AppUserProfile[]> {
		const url = '/volunteer/profile/app-users';
		const data = this.fetchTableItems({ ...params, url: url });
		return data;
	}

	async redFlagAppUser({
		appUserId,
		redFlag,
	}: {
		appUserId: string;
		redFlag: boolean;
	}): Promise<AppUserProfile> {
		const url = `/volunteer/profile/red-flag`;
		const { data } = await axios.post(url, { appUserId, redFlag });
		return data;
	}

	async suspendAppUser(appUserId: string): Promise<AppUserProfile> {
		const url = `/volunteer/profile/suspend`;
		const { data } = await axios.post(url, { appUserId });
		return data;
	}

	async resumeAppUser(appUserId: string): Promise<AppUserProfile> {
		const url = `/volunteer/profile/resume`;
		const { data } = await axios.post(url, { appUserId });
		return data;
	}

	async deleteAppUser(appUserId: string, reason: string): Promise<string> {
		const url = `/volunteer/profile/delete`;
		const { data } = await axios.post(url, { appUserId, reason });
		return data;
	}

	async unlinkAppUser(userId: string): Promise<void> {
		const url = `/vmp/var-links/unlink`;
		await axios.post(url, { userId });
	}

	async fetchUserItems(params: TableItemsOptions): Promise<any> {
		const url = '/admin/users';
		const data = this.fetchTableItems({ ...params, url: url });
		return data;
	}

	async fetchAuditLogsItems(params: TableItemsOptions): Promise<any> {
		const url = '/audit/audit-logs';
		const data = this.fetchTableItems({ ...params, url: url });
		return data;
	}

	// DO NOT use this, as there's a limit of 300 records, so it's not getting "all" records
	// async fetchUsers(): Promise<any> {
	// 	const url = '/admin/users';
	// 	const { data } = await axios.get(url);
	// 	return data;
	// }

	async fetchUser(id: string): Promise<any> {
		const url = `/admin/users/${id}`;
		const { data } = await axios.get(url);
		return data;
	}

	async fetchCenters(
		ngoId: number,
		params?: TableItemsOptions,
		publicOnly?: boolean,
	): Promise<any> {
		let url = `/contact/ngos/${ngoId}/centers`;
		if (params) {
			url = tableItemUrl({ ...params, url: url });
		}
		if (publicOnly) {
			url = url + `&publicOnly=true`;
		}
		const { data } = await axios.get(url);
		return data;
	}

	async fetchCauses(): Promise<any> {
		const { data } = await axios.get('/contact/causes');
		return data;
	}

	async fetchRecipients(): Promise<any> {
		const { data } = await axios.get('/contact/recipients');
		return data;
	}

	async fetchSdgoals(): Promise<any> {
		const { data } = await axios.get('/contact/sdgoals');
		return data;
	}

	async fetchVolunteerFunctions(): Promise<VolunteerFunction[]> {
		const { data } = await axios.get('/job/volunteer-functions');
		return data;
	}

	async searchAuditLogs(params: {
		page: number;
		limit: number;
		filters: {
			column: string;
			value: string;
		}[];
		sortOptions: {
			column: string;
			jsonbPath?: string;
			order: 'desc' | 'asc';
		}[];
	}): Promise<any> {
		const url = '/audit/audit-logs/search';
		const body = {
			page: params.page.toString(),
			limit: params.limit.toString(),
			filters: params.filters,
			sortOptions: params.sortOptions,
		};
		const { data } = await axios.post(url, body);
		return data;
	}

	async fetchAuditLog(id: string): Promise<any> {
		const url = `/audit/audit-logs/${id}`;
		const { data } = await axios.get(url);
		return data;
	}

	async fetchCenter(centerId: number) {
		const { data } = await axios.get(`/contact/centers/${centerId}`);
		return data;
	}

	async createUnit(params: any) {
		const { data } = await axios.post('/contact/centers', params);
		return data;
	}

	async updateUnit(params: any) {
		const { data } = await axios.put(
			`/contact/centers/${params.centerId}`,
			params.unit,
		);
		return data;
	}

	async deleteUnit(centerId: number): Promise<any> {
		const { data } = await axios.delete(`/contact/centers/${centerId}`);
		return data;
	}

	async updateNgo(params: any) {
		const { data } = await axios.put(`/contact/ngos/${params.id}`, params.ngo);
		return data;
	}

	async changeUserEmail({
		userId,
		email,
		ngoName,
	}: {
		userId: string;
		email: string;
		ngoName?: MultiLangText;
	}) {
		const { data } = await axios.post(`/admin/users/${userId}/change-email`, {
			email,
			ngoName,
		});
		return data;
	}

	async deleteUser(uuid: string): Promise<any> {
		const { data } = await axios.delete(`/admin/users/${uuid}`);
		return data;
	}

	async fetchNotificationBadges(): Promise<any> {
		const { data } = await axios.get('/notification/sc-admin/badges');
		return data;
	}

	async requestContactFileSignedUrl(body: any): Promise<any> {
		const { data } = await axios.post(
			'/contact/file-storage/request-upload-signed-url',
			body,
		);
		return data;
	}

	async fetchNgoById(ngoId: number) {
		const { data } = await axios.get(`/contact/ngos/${ngoId}`);
		return data;
	}

	async reviewNgoProfile(ngoId: number) {
		const { data } = await axios.post(
			`/contact/ngos/${ngoId}/ngo-profile/review`,
		);
		return data;
	}

	async unpublishNgoProfile(ngoId: number, reason?: string): Promise<Ngo> {
		const { data } = await axios.post(
			`/contact/ngos/${ngoId}/ngo-profile/unpublish`,
			{
				reason,
			},
		);
		return data;
	}

	async publishNgoProfile(ngoId: number): Promise<Ngo> {
		const { data } = await axios.post(
			`/contact/ngos/${ngoId}/ngo-profile/publish`,
		);
		return data;
	}

	async changeNgoApprovalStatus(
		ngoId: number,
		newStatus: NgoApprovalStatus,
		reason: string,
	) {
		const { data } = await axios.post(
			`contact/ngos/${ngoId}/change-approval-status`,
			{
				newStatus,
				reason,
			},
		);
		return data;
	}

	async revertNgoApprovalStatus(ngoId: number) {
		const { data } = await axios.post(
			`contact/ngos/${ngoId}/revert-approval-status`,
		);
		return data;
	}

	async deleteNgo(ngoId: number): Promise<any> {
		const { data } = await axios.delete(`/contact/ngos/${ngoId}`);
		return data;
	}

	async fetchRegions(): Promise<Region[]> {
		const { data } = await axios.get(`/location/regions`);
		return data;
	}

	async fetchDistricts(): Promise<District[]> {
		const { data } = await axios.get(`/location/districts`);
		return data;
	}

	async fetchSubDistricts(): Promise<SubDistrict[]> {
		const { data } = await axios.get(`/location/sub-districts`);
		return data;
	}

	async fetchConstantItems(
		constant: ListType,
		options: TableItemsOptions,
	): Promise<any> {
		const url = `/${constant.service}/${constant.key}/with-pagination`;
		const data = await this.fetchTableItems({ ...options, url: url });
		return data;
	}

	async createConstantItem(constant: ListType, params: any) {
		const { data } = await axios.post(
			`/${constant.service}/${constant.key}`,
			params,
		);
		return data;
	}

	async updateConstantItemsOrder(constant: ListType, ids: string[] | number[]) {
		const { data } = await axios.post(
			`/${constant.service}/${constant.key}/reorder`,
			ids,
		);
		return data;
	}

	async updateConstantItem(
		constant: ListType,
		constantItemId: number,
		params: any,
	) {
		const { data } = await axios.put(
			`/${constant.service}/${constant.key}/${constantItemId}`,
			params,
		);
		return data;
	}

	async deleteConstantItem(
		constant: ListType,
		constantItemId: number,
	): Promise<any> {
		const { data } = await axios.delete(
			`/${constant.service}/${constant.key}/${constantItemId}`,
		);
		return data;
	}

	async undoDeleteConstantItem(
		constant: ListType,
		constantItemId: number,
	): Promise<any> {
		const { data } = await axios.post(
			`/${constant.service}/${constant.key}/undo-delete`,
			{
				id: constantItemId,
			},
		);
		return data;
	}

	async fetchAppBannerItems(): Promise<any> {
		const { data } = await axios.get(`/volunteer/app-banners`);
		return data;
	}

	async reorderAppBannerItems(ids: string[]) {
		const { data } = await axios.post(`/volunteer/app-banners/reorder`, ids);
		return data;
	}

	async createAppBannerItem(appBannerDto: AppBannerDto): Promise<AppBanner> {
		const { data } = await axios.post(`/volunteer/app-banners`, appBannerDto);
		return data;
	}

	async updateAppBannerItem(
		id: string,
		appBannerDto: AppBannerDto,
	): Promise<AppBanner> {
		const { data } = await axios.put(
			`/volunteer/app-banners/${id}`,
			appBannerDto,
		);
		return data;
	}

	async deleteAppBannerItem(id: string): Promise<AppBanner> {
		const { data } = await axios.delete(`/volunteer/app-banners/${id}`);
		return data;
	}

	async getJobFileSignedUrl(filePath: string): Promise<any> {
		const { data } = await axios.get('/job/file-storage/signed-url', {
			params: { filePath },
		});
		return data;
	}

	async uploadImageToGCS(file: File) {
		const { filePath, signedUrl } = await this.requestContactFileSignedUrl({
			filename: file.name,
			contentType: file.type,
		});

		await axios.put(signedUrl, file, {
			headers: { 'Content-Type': file.type },
		});
		return filePath;
	}
	async fetchCategoryItems(): Promise<any> {
		const { data } = await axios.get(`/volunteer/categories`);
		return data;
	}

	async reorderCategoryItems(ids: string[]) {
		const { data } = await axios.post(`/volunteer/categories/reorder`, ids);
		return data;
	}

	async createCategoryItem(categoryDto: CategoryDto): Promise<Category> {
		const { data } = await axios.post(`/volunteer/categories`, categoryDto);
		return data;
	}

	async updateCategoryItem(
		id: string,
		categoryDto: CategoryDto,
	): Promise<Category> {
		const { data } = await axios.put(
			`/volunteer/categories/${id}`,
			categoryDto,
		);
		return data;
	}

	async deleteCategoryItem(id: string): Promise<Category> {
		const { data } = await axios.delete(`/volunteer/categories/${id}`);
		return data;
	}
}
