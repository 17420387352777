import { Module } from 'vuex';
import { RootState, FilterState } from '../types';

const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SET_LAST_ERROR = 'SET_LAST_ERROR';

const initialState: FilterState = {
	loading: 0,
	lastError: '',
	filter: [],
};

const store: Module<FilterState, RootState> = {
	namespaced: true,

	state: { ...initialState },

	mutations: {
		[SHOW_LOADING](state) {
			state.loading += 1;
		},

		[HIDE_LOADING](state) {
			state.loading -= 1;
		},

		[SET_LAST_ERROR](state, error) {
			state.lastError = error;
		},
	},

	actions: {},

	getters: {
		loading: state => Boolean(state.loading),
		lastError: state => state.lastError,
	},
};

export default store;
