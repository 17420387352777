import { SubjectRawRule } from '@casl/ability';
import { PackRule } from '@casl/ability/extra';
import { Action } from '../AppAbility';
import { MultiLangText, MultiLangFullText, LocaleCode } from './locale';
import { RegistrationType, NgoCategory } from './ngo';

export * from './locale';
export * from './ngo';

type Dictionary<T> = { [key: string]: T };

export interface EmailItem {
	email: string;
}

export type EmailItems = Dictionary<EmailItem>;

export interface PhoneItem {
	phone: string;
}

export type PhoneItems = Dictionary<PhoneItem>;

export interface AddressItem {
	addressLine: MultiLangText;
	mapLatLong?: string;
	regionCode?: string;
	districtCode?: string;
	subDistrictCode?: string;
}

export interface PlanItem {
	type: string;
	expiryDate: string;
	subscriptionDate: string;
}

export interface NgoProfile {
	legalEntityName: MultiLangText;
	registrationType?: RegistrationType;
	registrationNumber?: string;
	organisationType?: NgoCategory;
	religiousBackground: MultiLangText;
	socialEnterpriseImpact?: string;
	socialEnterpriseEmployment?: string;
	socialEnterpriseInvestment?: string;
	unpublishReason?: string;
	cause: [];
	recipient: [];
	sdgoal: [];
	websiteUrl: string;
	description: MultiLangText;
	establishYear: string;
	missionAndVision: MultiLangText;
	createdAt?: string;
	updatedAt?: string;
	reviewedAt?: string | null;
	unpublishedAt?: string | null;
	isPublished: boolean;
	lastModifiedInfo?: object;
}

export enum TellUsMoreJobRole {
	administrativeSupport = 'administrativeSupport',
	careTaking = 'careTaking',
	cleaning = 'cleaning',
	designing = 'designing',
	drivingOrDelivery = 'drivingOrDelivery',
	eventAssistance = 'eventAssistance',
	fundRaising = 'fundRaising',
	hotlineSupport = 'hotlineSupport',
	itAssistance = 'itAssistance',
	marketingOrAdvocacy = 'marketingOrAdvocacy',
	mentoring = 'mentoring',
	microVolunteering = 'microVolunteering',
	packing = 'packing',
	performing = 'performing',
	photographyAndVideoEdit = 'photographyAndVideoEdit',
	professionalConsulting = 'professionalConsulting',
	projectPlanning = 'projectPlanning',
	repairing = 'repairing',
	research = 'research',
	translationAndEditorial = 'translationAndEditorial',
	tutoringAndTeaching = 'tutoringAndTeaching',
	visit = 'visit',
	volunteerInternship = 'volunteerInternship',
	volunteerTraining = 'volunteerTraining',
	other = 'other',
}

export interface Center {
	id?: number;
	code: string;
	name: MultiLangText;
	emails: EmailItems;
	phones: PhoneItems;
	isVirtual: boolean;
	addressLine: MultiLangText;
	mapLatLong?: string;
	regionCode?: string;
	districtCode?: string;
	subDistrictCode?: string;
	logoUrl: string;
	imageUrl: string;
	ngo?: Ngo;
	ngoId: number;
	missionAndVision: MultiLangText;
	cause?: string[];
	recipient?: string[];
	sdgoal?: string[];
	displayOnWebAndApp: boolean;
	bannerUrl: string;
}

export enum NgoStatus {
	inProgress = 'inProgress',
	completed = 'completed',
	verified = 'verified',
	expired = 'expired',
	rejected = 'rejected',
	suspended = 'suspended',
	pending = 'pending',
}

export enum NgoApprovalStatus {
	pending = 'pending',
	approved = 'approved',
	rejected = 'rejected',
	suspended = 'suspended',
}

export enum NgoOnboardingStatus {
	verified = 'verified',
	expired = 'expired',
	pending = 'pending',
	completed = 'completed',
	inProgress = 'inProgress',
}

export interface NgoOnboardingDataStatus {
	additionalInfoFinishedCount?: number;
	additionalInfoTotalCount?: number;
	canSubmit?: boolean;

	profileEditCompleted?: boolean;
	unitEditCompleted?: boolean;
	tellUsMoreCompleted?: boolean;
	additionalInformationSubmitted?: boolean;
}

export interface NgoOnboardingAdditionalInformation {
	consentJC: boolean;
	howDidYouKnowSocialCareer: string[];
	howDidYouKnowSocialCareerOthersText?: string;
	conductsBackgroundChecks: string;
	conductsBackgroundChecksYesText?: string;
	hasInsuranceForOrganisation: string[];
	hasInsuranceForVolunteer: string;
	firstAidStaffCount: string;
	checkSCRC: string;
	riskManagementInPlace: string;
	isMemberOfCharitableBody: string;
	isMemberOfCharitableBodyYesText?: string;
	isReligiousOrg: string;
	isReligiousOrgYesText?: string;
	isPolitical: string;
}

export interface NgoOnboardingTellUsMore {
	jobRoleRecruiting: TellUsMoreJobRole[];
	jobRoleRecruitingOtherText: string;
	acceptEnglishVolunteer: string;
	acceptEnglishVolunteerNoReason: string;
	acceptYoungVolunteer: string;
	acceptYoungVolunteerNoReason: string;
}

export interface NgoOnboardingData {
	status?: NgoOnboardingDataStatus;
	additionalInformation?: NgoOnboardingAdditionalInformation;
	tellUsMore?: NgoOnboardingTellUsMore;
}

export interface Ngo {
	id?: number;
	code: string;
	name: MultiLangText;
	mainAccountId?: string;
	mainContact: MainContactUser;
	ngoProfile: NgoProfile;
	logoUrl: string;
	imageUrl: string;
	category: string;
	status?: NgoStatus | null; // Todo: provide default Status
	approvalStatus?: NgoApprovalStatus;
	previousApprovalStatus?: NgoApprovalStatus;
	approvalReason?: string;
	onboardingStatus?: NgoOnboardingStatus;
	subscribedPlans?: PlanItem[];
	consents: {
		[consentKey: string]: boolean;
	};
	centers?: Center[];
	dictionaries?: unknown[]; // not relevant in this project
	signupData?: { [k: string]: any };
	onboardingData?: NgoOnboardingData;
	remarks?: string;
	createdAt?: string;
	updatedAt?: string;
	lastModifiedInfo?: object;
	profileUpdated: boolean;
}

export type Cause = Constant;

export type Recipient = Constant;

export type Sdgoal = Constant;

export interface CodeValue {
	code: string;
	name: MultiLangFullText;
}

export type Constant = CodeValue & {
	id: number;
	position?: number;
	deletedAt: Date;
	lastModifiedInfo?: LastModifiedInfo;
};

export interface LastModifiedInfo {
	userId?: string;
}

export interface MainContactUser {
	name: string;
	jobTitle: string;
	phone: string;
	email: string;
}

export interface DataTableExportField {
	name: string;
	key: string;
}

export interface DataTableProps {
	systemHeaders: Header[];
	items?: TableItems;
	totalItems?: number;
	itemKey?: string;
	getItems?: Function;
	allowExport?: boolean;
	onExport?: (opts: TableItemsOptions, fields: DataTableExportField[]) => void;
	props: Props;
	rowNavigation?: Function;
	disableFilter?: boolean;
	exportFilename?: Function;
	loading?: boolean;
	hidePagination?: boolean;
}

export enum TableColumnType {
	string = 'string',
	number = 'number',
	boolean = 'boolean',
	date = 'date',
}

export interface Header {
	text?: string; //TranslateResult
	key?: string;
	keyOptions?: any;
	value: string;
	align?: string;
	sortable?: boolean;
	nonDraggable?: boolean;
	width?: string | number;
	filterable?: boolean;
	isObject?: boolean;
	langData?: any;
	type?: TableColumnType;
	name?: string;
	fixed?: boolean;
}

export type HeaderPreference = Pick<Header, 'key' | 'align'>;

export interface TableItemsOptions {
	url?: string;
	page: number;
	itemsPerPage: number;
	sortBy: string[];
	sortDesc: boolean[];
	groupBy: string[];
	groupDesc: boolean[];
	multiSort: boolean;
	mustSort: boolean;
	search: { headers: string[]; text: string };
	filter: number[];
	filterParams: { [f: number]: string };
	isExport?: boolean;
}

export interface TableItemsResult {
	result: TableItems;
	total: number;
}

export type TableItems = ({ id: string | number } | Record<string, any>)[];

export interface Toolbar {
	title: string;
	button: { text: string; icon: string; action: Function };
}

export type Props = { [key: string]: boolean | string };

export enum UserAccountStatusCode {
	active = 'active',
	suspended = 'suspended',
}

export interface UserPermission {
	id: string;
	item: UserPermissionItem;
	permission: Action;
	roles?: UserRole[];
	roleTemplates?: UserRoleTemplate[];
}

export interface UserPermissionItemGroup {
	id: string;
	index: number;
	code: string;
	name: MultiLangText;
	items?: UserPermissionItem[];
}

export interface UserPermissionItem {
	id: string;
	index: number;
	code: string;
	name: MultiLangText;
	group: UserPermissionItemGroup;
	permissions: UserPermission[];
}
export interface UserRole {
	id: string;
	code?: string;
	name: string;
	permissions: UserPermission[];
	isPrivate: boolean;
	users?: UserAccount[];
	ngoId?: number;
}

export interface UserRoleTemplate {
	id: string;
	name: MultiLangText;
	permissions: UserPermission[];
}

export interface UserAccount {
	id: string;
	userCode?: string;
	status: UserStatus;
	name: string;
	jobTitle?: string;
	email: string;
	phone?: string;
	role?: UserRole;
	ngoId: number;
	centerId?: number;
	accessCenters?: AccessCenter[];
	userPreference?: UserPreference;
	isSignupUser?: boolean;
	lastSignInAt?: Date;
	createdAt: Date;
	updatedAt: Date;
}

export interface AccessCenter {
	id: number;
	userId: string;
	user?: UserAccount;
	centerId: number;
}

export interface UserPreference {
	id: string;
	language: LocaleCode;
	dateFormat: string;
	twoFA: boolean;
	saveTableColumnSettings: boolean;
	calendarFormat: CalendarFormat;
	tableColumnSettings: { [k: string]: any };
	userId: string;
}

export enum CalendarFormat {
	Standard = 'standard',
	Custom = 'custom',
}

export interface AuditLog {
	id: number;
	event: string;
	service: string;
	object: string;
	updatedBy: UserAccount;
	transactedAt: Date;
	createdAt: Date;
	data: any;
	beforeData: any;
}

export interface CenterOption {
	value: Center | null;
	text: string;
}

export type UserPermissionRawRule = SubjectRawRule<Action, any, any>;
export interface AccessTokenDto {
	email: string;
	roles: [];
	centerId: number;
	ngoId: number;
	permissions: PackRule<UserPermissionRawRule>[];
}

export enum NgoListType {
	organisationList = 'organisationList',
	applications = 'applications',
	inactive = 'inactive',
}

export interface Region {
	code: string;
	name: MultiLangText;
	districts?: District[];
	deletedAt: Date;
}

export interface District {
	code: string;
	name: MultiLangText;
	region?: Region;
	regionCode: string;
	subDistricts?: SubDistrict[];
	deletedAt: Date;
}

export interface SubDistrict {
	code: string;
	name: MultiLangText;
	district?: District;
	districtCode: string;
	deletedAt: Date;
}

export interface ListType {
	key: string;
	service: string;
	fields?: string[];
}

export interface ListItem {
	id?: number;
	code: string;
	name: MultiLangText;
	position?: number;
	regionCode?: string;
	districtCode?: string;
	deletedAt: Date;
}

// export interface TableItemsResult {
// 	result: TableItems;
// 	total: number;
// }

export interface CodeValue {
	code: string;
	name: MultiLangFullText;
}

export class AppBannerDto {
	position?: number;
	imageUrl?: MultiLangText;
	webImageUrl?: MultiLangText;
	destinationType?: DestinationType | null;
	destinationUrl?: MultiLangText;
	eventId?: string | null;
	jobId?: string | null;
	title?: string;
	appVisible?: boolean;
	webVisible?: boolean;
}

export enum DestinationType {
	web = 'web',
	app = 'app',
}

export interface AppBanner {
	id?: string;
	position?: number;
	imageUrl: MultiLangText;
	destinationType?: DestinationType | null;
	destinationUrl?: MultiLangText;
	eventId?: string;
	jobId?: string;
	webVisible?: boolean;
	appVisible?: boolean;
	title: string;
	createdAt?: string;
	updatedAt?: string;
	webImageUrl: MultiLangText;
}

export interface Category {
	id: string;
	position?: number;
	status: boolean;
	name: string;
	iconUrl: string;
	title: MultiLangText;
	description: MultiLangText;
	pageTitle: MultiLangText;
	metaDescription: MultiLangText;
	pageBannerDesktop: string;
	pageBannerMobile: string;
	createdAt?: string;
	updatedAt?: string;
	filter: JobsFilterDto;
}

export enum DayOfWeekOption {
	hasWeekday = 'hasWeekday',
	hasWeekend = 'hasWeekend',
}

// export enum AgeOption {
// 	lt18 = 'lt18',
// 	gte18 = 'gte18',
// }

export type IntRange = {
	start: number;
	end: number;
};

export type JobsFilterDto = {
	// dayOfWeekOption?: DayOfWeekOption;
	dayOfWeekOptions?: DayOfWeekOption[];
	regionCodes?: string[];
	districtCodes?: string[];
	subDistrictCodes?: string[];
	// isVirtual?: boolean;
	// isEditorChoice?: boolean;
	// isNgoDetailsJobEventListFilter?: boolean;
	restrictions?: string[];
	causes?: string[];
	recipients?: string[];
	sdgoals?: string[];
	volunteerFunctions?: string[];
	// ageOption?: AgeOption; // for App
	ageRange?: IntRange;
	languages?: string[];
	// ngoId?: number;
	expectedCommitHoursRange?: IntRange;
};

export class CategoryDto {
	position?: number;
	status?: boolean;
	name?: string;
	iconUrl?: string;
	title?: MultiLangText;
	description?: MultiLangText;
	pageTitle?: MultiLangText;
	metaDescription?: MultiLangText;
	pageBannerDesktop?: string;
	pageBannerMobile?: string;
	filter?: JobsFilterDto;
}
export interface AppUserProfile {
	id: string;
	userCode?: string;
	chineseName: string;
	firstName: string;
	lastName: string;
	email?: string;
	mobileNumber?: string;
	redFlag: boolean;
	status: UserStatus;
	lastActiveAt?: string;
	createdAt?: string;
	updatedAt?: string;
	hasVarLink: boolean;
}

export enum UserStatus {
	active = 'active',
	suspended = 'suspended',
}
