



















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class SnackBar extends Vue {
	@Prop() text: string | string[] | undefined;

	@Prop({ type: String })
	type: 'success' | 'error' | undefined;

	get isMultiLine() {
		if (!this.text) {
			return false;
		}
		return typeof this.text !== 'string' && this.text.length > 1;
	}

	content = '';
	color = 'primary';
	timeout = '10000';
	visible = false;

	@Watch('text')
	onTextChanged(value: string) {
		if (value) {
			this.content = value;
		}
		this.visible = !!value;
	}

	@Watch('type')
	onTypeChanged(value: string) {
		if (value) {
			this.color = value === 'error' ? 'red accent-5' : 'primary';
		}
	}

	onClose() {
		this.$emit('closed');
	}

	onVisibleChanged(visible: any) {
		if (!visible) {
			this.$emit('closed');
		}
	}
}
