import Vue from 'vue';
import VueLodash from 'vue-lodash';

/**
 * For tree-shaking, list all needed functions
 */
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import camelCase from 'lodash/camelCase';
import pickBy from 'lodash/pickBy';

Vue.use(VueLodash, {
	lodash: {
		get,
		orderBy,
		cloneDeep,
		debounce,
		isEqual,
		keyBy,
		uniq,
		camelCase,
		pickBy,
	},
});
