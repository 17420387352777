import Vue from 'vue';
import Component from 'vue-class-component';
import { ButtonMixin } from './button-mixins';
import { CardMixin } from './card-mixins';
import { ChipMixin } from './chip-mixins';
import { InputMixin } from './input-mixin';
import { SpaceMixin } from './space-mixins';

@Component({
	mixins: [ButtonMixin, SpaceMixin, CardMixin, ChipMixin, InputMixin],
})
export class StyleMixin extends Vue {
	// global mixin

	textareaAttrs = {
		outlined: true,
	};

	tableMessageTextAttrs = {
		class: 'body-text mono700--text mt-3',
	};

	tableProgressBarAttrs = {
		color: 'primary darken-1',
		indeterminate: true,
		absolute: true,
	};

	/**
	 * Icons
	 */
	readonly deleteIconClass = 'ri-delete-bin-line';
	readonly editIconClass = 'ri-pencil-line';
	readonly closeIconClass = 'ri-close-line';
	readonly arrowDownIconClass = 'ri-arrow-down-s-line';
}
