import { unparse } from 'papaparse';
import { TableItemsOptions } from '../../types';

export enum TableCode {
	staffAccount = 'staffAccounts.list',
	ngoApproved = 'ngo.list.approved',
	ngoApplications = 'ngo.list.applications',
	ngoInactive = 'ngo.list.inactive',
	auditLog = 'auditLog.list',
	appAccount = 'appAccounts.list',
	constantItemList = 'constants.list',
	editorChoiceList = 'editorChoices.list',
	appBannerList = 'appBanner.list',
	categorySetting = 'categorySetting',
}

export const HIDE_COLUMN_ALIGN = ' d-none';

enum QUERY_PARAM {
	SKIP = 'skip=',
	TAKE = '&take=',
	SORT = '&sort=',
	SEARCH = '&search=',
	FILTER = '&filter=',
	TIMEZONE = '&tz=',
}

function skipTake(params: TableItemsOptions): string {
	const skip = (params.page - 1) * params.itemsPerPage;
	const take = params.itemsPerPage;
	return QUERY_PARAM.SKIP + skip + QUERY_PARAM.TAKE + take;
}

function sort(params: TableItemsOptions): string {
	if (params.sortBy?.length > 0) {
		let url: string = QUERY_PARAM.SORT;
		params.sortBy.forEach((fieldName: string, index) => {
			url += `${index > 0 ? ',' : ''}${
				params.sortDesc[index] ? '-' : ''
			}${fieldName}`;
		});
		return url;
	}
	return '';
}

function search(params: TableItemsOptions): string {
	if (params.search?.headers.length > 0) {
		let url: string =
			QUERY_PARAM.SEARCH + encodeURIComponent(params.search.text.trim()) + ':';
		params.search.headers.forEach((fieldName: string, index) => {
			url += `${index > 0 ? ',' : ''}${fieldName}`;
		});
		return url;
	}
	return '';
}

function filter(params: TableItemsOptions): string {
	if (params.filter?.length > 0) {
		const url: string =
			QUERY_PARAM.FILTER +
			params.filter
				.map(f =>
					params.filterParams[f] ? `${f}:${params.filterParams[f]}` : f,
				)
				.join(',');
		return url;
	}
	return '';
}

function timeZone(): string {
	return QUERY_PARAM.TIMEZONE + new Date().getTimezoneOffset().toString();
}

export function tableItemUrl(params: TableItemsOptions): string {
	return (
		params.url +
		(params.url?.includes('?') ? '&' : '?') +
		skipTake(params) +
		sort(params) +
		search(params) +
		filter(params) +
		timeZone()
	);
}

export function downloadDataAsCsvFile(
	filename: string,
	data: any[][],
	fields: string[],
) {
	const csvData = unparse(
		{ fields, data },
		{
			quotes: false,
			quoteChar: '"',
			escapeChar: '"',
			delimiter: ',',
			header: true,
			newline: '\r\n',
		},
	);
	const contentType = 'text/csv';
	const blob = new Blob([csvData], { type: contentType });
	const e = document.createEvent('MouseEvents');
	const a = document.createElement('a');
	a.download = `${filename}.csv`;
	a.href = URL.createObjectURL(blob);
	a.rel = 'noopener';
	a.target = '_blank';
	a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
	e.initEvent('click', true, false);
	a.dispatchEvent(e);
}
