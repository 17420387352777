import { Module } from 'vuex';
import { AdminState, RootState } from '../types';
import { api } from '../../plugins/salut-api';
import { UserAccount } from '../../types';

const ADD_TO_USERS = 'ADD_TO_USERS';
const SET_USER_ACCOUNT = 'SET_USER_ACCOUNT';

export const AdminActionKeys = {
	fetchUsersByIds: 'fetchUsersByIds',
	fetchUser: 'fetchUser',
	changeUserEmail: 'changeUserEmail',
};

export const AdminGetterKeys = {
	getUserById: 'getUserById',
	currentUserAccount: 'userAccount',
};

const initialState: AdminState = {
	usersById: {},
	currentUserAccount: null,
};

export const store: Module<AdminState, RootState> = {
	namespaced: true,

	state: { ...initialState },

	mutations: {
		[ADD_TO_USERS](state, user: UserAccount) {
			state.usersById[user.id] = user;
		},
		[SET_USER_ACCOUNT](state, currentUserAccount: UserAccount) {
			state.currentUserAccount = currentUserAccount;
		},
	},

	actions: {
		async [AdminActionKeys.fetchUsersByIds](
			{ commit, getters },
			{ userIds }: { userIds: string[] },
		) {
			for (const userId of userIds ?? []) {
				if (userId && !getters[AdminGetterKeys.getUserById](userId)) {
					const user = await api.fetchUser(userId);
					commit(ADD_TO_USERS, user);
				}
			}
		},
		async [AdminActionKeys.fetchUser](
			{ commit },
			{ userId }: { userId: string },
		) {
			const user = await api.fetchUser(userId);
			commit(SET_USER_ACCOUNT, user);
		},
		async [AdminActionKeys.changeUserEmail](
			{ commit },
			{ userId, email, ngoName },
		) {
			const updatedUser = await api.changeUserEmail({
				userId,
				email,
				ngoName,
			});
			commit(SET_USER_ACCOUNT, updatedUser);
			return updatedUser;
		},
	},

	getters: {
		[AdminGetterKeys.getUserById]: state => (id: string) => state.usersById[id],
		[AdminGetterKeys.currentUserAccount]: state => state.currentUserAccount,
	},
};

export default store;
