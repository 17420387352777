import { Store } from 'vuex';
import { RootState } from '../types';
import { getRulesFromAccessToken, ability } from '../../lib/casl';
import { SET_ACCESS_TOKEN } from '../modules/auth';

export const abilityPlugin = (store: Store<RootState>) => {
	const accessToken = store.getters['auth/accessToken'];

	if (accessToken) {
		const rules = getRulesFromAccessToken(accessToken);
		ability.update(rules);
	}

	return store.subscribe(mutation => {
		switch (mutation.type) {
			case `auth/${SET_ACCESS_TOKEN}`:
				{
					const accessToken = mutation.payload;
					const rules = accessToken ? getRulesFromAccessToken(accessToken) : [];
					ability.update(rules);
				}
				break;
		}
	});
};
