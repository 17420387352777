import { ListType } from '../types';

export const REGION_KEY = 'regions';
export const DISTRICTS_KEY = 'districts';
export const SUB_DISTRICTS_KEY = 'sub-districts';
export const LOCATION_SERVICE = 'location';

export const LIST_TYPES: ListType[] = [
	{ key: 'causes', service: 'contact' },
	{ key: 'recipients', service: 'contact' },
	{ key: 'sdgoals', service: 'contact' },
	{ key: 'religion-backgrounds', service: 'contact' },
	{ key: 'activity-themes', service: 'event' },
	{ key: 'activity-types', service: 'event' },
	{ key: 'service-types', service: 'event' },
	{ key: 'target-audiences', service: 'event' },
	{ key: 'event-types', service: 'job' },
	{ key: 'session-types', service: 'job' },
	{ key: 'volunteer-functions', service: 'job' },
	{ key: REGION_KEY, service: LOCATION_SERVICE },
	{
		key: DISTRICTS_KEY,
		service: LOCATION_SERVICE,
		fields: ['regionCode'],
	},
	{
		key: SUB_DISTRICTS_KEY,
		service: LOCATION_SERVICE,
		fields: ['districtCode'],
	},
];
