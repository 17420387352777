export enum LocaleCode {
	EN = 'en',
	TC = 'zhHant',
	SC = 'zhHans',
}

export const LocaleValidationCode = {
	en: 'en',
	zhHant: 'zh_TW',
	zhHans: 'zh_CN',
};

export const AvailableLocaleCodes = [
	LocaleCode.EN,
	LocaleCode.TC,
	LocaleCode.SC,
];

export type MultiLangFullText = {
	[key in LocaleCode]: string;
};

export type MultiLangText = Partial<MultiLangFullText>;

export const EmptyMultiLangText: MultiLangText = {
	[LocaleCode.EN]: '',
	[LocaleCode.TC]: '',
	[LocaleCode.SC]: '',
};

export function isSupportedLocale(key: string): key is LocaleCode {
	return AvailableLocaleCodes.includes(key as LocaleCode);
}
