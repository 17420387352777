export const accountCircleIcon = 'ri-account-circle-line';
export const addIcon = 'ri-add-line';
export const arrowDropDownIcon = 'ri-arrow-drop-down-line';
export const arrowDropRightIcon = 'ri-arrow-drop-right-line';
export const arrowDropUpIcon = 'ri-arrow-drop-up-line';
export const arrowLeftIcon = 'ri-arrow-left-s-line';
export const arrowRightIcon = 'ri-arrow-right-s-line';
export const artboardIcon = 'ri-artboard-line';
export const attachmentIcon = 'ri-attachment-line';
export const briefcaseIcon = 'ri-briefcase-2-line';
export const calendarIcon = 'ri-calendar-event-line';
export const checkboxCircleIcon = 'ri-checkbox-circle-line';
export const checkIcon = 'ri-check-line';
export const closeCircleIcon = 'ri-close-circle-line';
export const riCloseIcon = 'ri-close-line';
export const coinsIcon = 'ri-coins-line';
export const couponIcon = 'ri-coupon-line';
export const contactsIcon = 'ri-contacts-line';
export const deleteIcon = 'ri-delete-bin-line';
export const downloadIcon = 'ri-download-line';
export const errorWarningIcon = 'ri-error-warning-line';
export const excelIcon = 'ri-file-excel-2-line';
export const eyeIcon = 'ri-eye-line';
export const eyeCloseIcon = 'ri-eye-close-line';
export const fileCopyIcon = 'ri-file-copy-line';
export const fileListIcon = 'ri-file-list-3-line';
export const fileTextIcon = 'ri-file-text-line';
export const riFilterIcon = 'ri-filter-line'; //error results if renamed to filterIcon
export const folderIcon = 'ri-folder-open-line';
export const homeIcon = 'ri-home-line';
export const informationIcon = 'ri-information-line';
export const listSettingIcon = 'ri-list-settings-line';
export const mailIcon = 'ri-mail-line';
export const mapPinIcon = 'ri-map-pin-line';
export const menu2Icon = 'ri-menu-2-line';
export const menuIcon = 'ri-menu-line';
export const moreIcon = 'ri-more-2-fill';
export const editIcon = 'ri-pencil-line';
export const paymentIcon = 'ri-bank-card-line';
export const phoneIcon = 'ri-phone-line';
export const printerIcon = 'ri-printer-line';
export const qrCodeIcon = 'ri-qr-code-line';
export const qrScanIcon = 'ri-qr-scan-2-line';
export const receiptIcon = 'ri-newspaper-line';
export const refundIcon = 'ri-refund-2-line';
export const roadMapIcon = 'ri-road-map-line';
export const searchIcon = 'ri-search-2-line';
export const settingsIcon = 'ri-settings-3-line';
export const timeIcon = 'ri-time-line';
export const timerIcon = 'ri-timer-line';
export const todoIcon = 'ri-todo-line';
export const toolsIcon = 'ri-tools-fill';
export const uploadCloudIcon = 'ri-upload-cloud-2-line';
export const uploadIcon = 'ri-upload-line';
export const userAddIcon = 'ri-user-add-line';
export const userFollowIcon = 'ri-user-follow-line';
export const userGroupIcon = 'ri-group-line';
export const userIcon = 'ri-user-line';
export const userSharedIcon = 'ri-user-shared-line';

export const clipboardIconClass = 'ri-clipboard-line';

/* 
	declared in vuetify.ts (global icons)
*/

export const globalIcons = {
	complete: checkIcon,
	cancel: riCloseIcon,
	close: riCloseIcon,
	delete: deleteIcon,
	clear: riCloseIcon,
	success: checkboxCircleIcon,
	info: informationIcon,
	warning: errorWarningIcon,
	error: closeCircleIcon,
	prev: arrowLeftIcon,
	next: arrowRightIcon,
	sort: arrowDropUpIcon,
	expand: arrowDropDownIcon,
	menu: menu2Icon,
	subgroup: arrowDropDownIcon,
	dropdown: arrowDropDownIcon,
	edit: editIcon,
	file: attachmentIcon,
};
