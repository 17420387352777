import { Module } from 'vuex';

import { RootState, AuditState } from '../types';
import { api } from '../../plugins/salut-api';
import { TableItemsOptions } from '../../types';

const SHOW_LOADING = 'SHOW_LOADING';
const SET_DETAILS = 'SET_DETAILS';
const HIDE_LOADING = 'HIDE_LOADING';
const SET_LAST_ERROR = 'SET_LAST_ERROR';
const SET_PAGE_DATA = 'SET_PAGE_DATA';

const initialState: AuditState = {
	pageData: { items: [], total: 0 },
	details: undefined,
	loading: 0,
	lastError: '',
};

const store: Module<AuditState, RootState> = {
	namespaced: true,

	state: { ...initialState },

	actions: {
		async fetchMany({ commit }, options: TableItemsOptions) {
			try {
				commit(SHOW_LOADING);

				const { result, total } = await api.fetchAuditLogsItems(options);

				commit(SET_PAGE_DATA, { result, total });
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},

		async fetch({ commit }, { id }) {
			try {
				commit(SHOW_LOADING);

				const auditLog = await api.fetchAuditLog(id);
				commit(SET_DETAILS, {
					...auditLog,
					updatedBy: auditLog.authorUserId
						? await api.fetchUser(auditLog.authorUserId)
						: null,
				});
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},
	},

	mutations: {
		[SET_DETAILS](state, data) {
			state.details = {
				...data,
			};
		},

		[SET_PAGE_DATA](state, { result, total }) {
			state.pageData = {
				items: result,
				total: total,
			};
		},

		[SHOW_LOADING](state) {
			state.loading += 1;
		},

		[HIDE_LOADING](state) {
			state.loading -= 1;
		},

		[SET_LAST_ERROR](state, error) {
			state.lastError = error;
		},
	},

	getters: {
		loading: state => Boolean(state.loading),
		lastError: state => state.lastError,
		details: state => state.details,
		pageData: state => state.pageData,
	},
};

export default store;
