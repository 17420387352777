import { Module } from 'vuex';
import { api } from '../../plugins/salut-api';
import { TableItemsOptions } from '../../types';
import { RootState, VolunteerState } from '../types';

const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SET_LAST_ERROR = 'SET_LAST_ERROR';
const SET_APP_USERS_PAGE_DATA = 'SET_APP_USERS_PAGE_DATA';

export const VolunteerActionKeys = {
	fetchAppUsers: 'fetchAppUsers',
};

export const VolunteerGetterKeys = {
	appUsersPageData: 'appUsersPageData',
	loading: 'loading',
	lastError: 'lastError',
};

const store: Module<VolunteerState, RootState> = {
	namespaced: true,

	state: {
		loading: 0,
		lastError: '',
		appUsersPageData: { items: [], total: 0 },
	},

	mutations: {
		[SHOW_LOADING](state) {
			state.loading += 1;
		},

		[HIDE_LOADING](state) {
			state.loading -= 1;
		},

		[SET_LAST_ERROR](state, error) {
			state.lastError = error;
		},

		[SET_APP_USERS_PAGE_DATA](state, { result, total }) {
			state.appUsersPageData = {
				items: result,
				total: total,
			};
		},
	},

	actions: {
		async [VolunteerActionKeys.fetchAppUsers](
			{ commit },
			options: TableItemsOptions,
		) {
			try {
				commit(SHOW_LOADING);

				const data = await api.fetchAppUserItems(options);

				commit(SET_APP_USERS_PAGE_DATA, data);
			} catch (error) {
				commit(SET_LAST_ERROR, error);
			} finally {
				commit(HIDE_LOADING);
			}
		},
	},

	getters: {
		[VolunteerGetterKeys.appUsersPageData]: state => state.appUsersPageData,
		[VolunteerGetterKeys.loading]: state => Boolean(state.loading),
		[VolunteerGetterKeys.lastError]: state => state.lastError,
	},
};

export default store;
