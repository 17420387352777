import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export class ButtonMixin extends Vue {
	public static PrimaryButtonAttrsClass = 'py-1 px-4 rounded-lg';

	//
	// admin portal styling
	//

	primaryButtonAttrs = {
		color: 'primary',
	};

	primaryButtonIconAttrs = {};

	secondaryButtonAttrs = {
		text: true,
		color: 'secondary',
	};

	// delete button
	deleteButtonAttrs = {
		text: true,
		color: 'error',
		class: 'py-1 px-4 rounded-lg',
	};

	secondaryButtonIconAttrs = {};

	primaryButtonTextAttrs = {
		class: 'd-none d-sm-block ml-3',
	};

	secondaryButtonTextAttrs = {
		class: 'd-none d-sm-block ml-3',
	};

	//
	// ngo portal styling
	//

	// primary button (CTA)
	// primaryButtonAttrs = {
	// 	color: 'primary darken-1',
	// 	class: ButtonMixin.PrimaryButtonAttrsClass,
	// 	elevation: 0,
	// };

	// primaryButtonIconAttrs = {};

	// primaryButtonTextAttrs = {
	// 	color: 'primary darken-1',
	// 	class: 'd-none d-sm-block ml-2 button-text',
	// };

	// text button
	textButtonAttrs = {
		text: true,
		...this.primaryButtonAttrs,
	};

	textButtonIconAttrs = {
		class: 'mr-2',
	};

	textButtonRightIconAttrs = {
		class: 'ml-2',
	};

	textButtonTextAttrs = {
		color: 'primary darken-1',
		class: 'd-none d-sm-block button-text',
	};

	// outlined button
	// outlinedButtonAttrs = {
	// 	text: true,
	// 	outlined: true,
	// 	...this.primaryButtonAttrs,
	// };

	// outlinedButtonIconAttrs = {};

	// outlinedButtonTextAttrs = {
	// 	...this.primaryButtonTextAttrs,
	// };

	// secondary button
	// secondaryButtonAttrs = {
	// 	color: 'secondary',
	// 	class: 'rounded-lg',
	// 	elevation: 0,
	// };

	// primary button (CTA)
	// peachButtonAttrs = {
	// 	color: 'peach',
	// 	class: 'py-1 px-4 rounded-lg ',
	// 	elevation: 0,
	// };

	// peachButtonTextAttrs = {
	// 	class: 'button-text mono800--text',
	// };

	// peach text button
	// peachTextButtonAttrs = {
	// 	text: true,
	// 	...this.peachButtonAttrs,
	// };

	// peachTextButtonTextAttrs = {
	// 	color: 'peach',
	// 	class: 'button-text',
	// };

	// delete button
	// deleteButtonAttrs = {
	// 	text: true,
	// 	color: 'error',
	// 	class: 'py-1 px-4 rounded-lg',
	// };

	// deleteIconButtonAttrs = {
	// 	color: 'error',
	// };

	// deleteButtonTextAttrs = {
	// 	color: 'error',
	// 	class: 'd-none d-sm-block ml-2 button-text',
	// };

	// // disabled button
	// disabledButtonAttrs = {
	// 	color: 'mono200',
	// 	class: 'py-1 px-4 rounded-lg ',
	// 	elevation: 0,
	// };

	// disabledButtonTextAttrs = {
	// 	class: 'button-text white--text',
	// };

	// iconButtonAttrs = {
	// 	icon: true,
	// 	color: 'primary',
	// };
}
