import { Module } from 'vuex';
import { RootState, TableState } from '../types';
import cloneDeep from 'lodash/cloneDeep';

export const SET_TABLE_OPTIONS = 'SET_TABLE_OPTIONS';
const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const RESET_STATE = 'RESET_STATE';

export const TableGetterKeys = {
	loading: 'loading',
	tableOptions: 'tableOptions',
};

const initialState: TableState = {
	loading: 0,
	tableOptions: {},
};

const store: Module<TableState, RootState> = {
	namespaced: true,

	state: cloneDeep(initialState),

	actions: {
		resetState({ commit }) {
			commit(RESET_STATE);
		},
	},

	mutations: {
		[RESET_STATE](state) {
			Object.assign(state, cloneDeep(initialState));
		},

		[SET_TABLE_OPTIONS](state, tableOptions) {
			state.tableOptions[tableOptions.key] = tableOptions.options;
		},

		[SHOW_LOADING](state) {
			state.loading += 1;
		},

		[HIDE_LOADING](state) {
			state.loading -= 1;
		},
	},

	getters: {
		[TableGetterKeys.loading]: state => Boolean(state.loading),
		[TableGetterKeys.tableOptions]: state => (tableCode: string) =>
			state.tableOptions[tableCode] ?? null,
	},
};

export default store;
