import Vue from 'vue';
import Vuetify, { colors, Ripple } from 'vuetify/lib';
import { globalIcons } from '../assets/style/icon';

Vue.use(Vuetify, { directives: { Ripple } });

const lime = '#DCFA6E';
const kimberly = '#666B91';
const peach = '#FA9470';

const offWhite = '#F2F1E7';

const mono50 = '#FAFAFA';
const mono200 = '#EFEFEF';
const mono300 = '#E1E1E1';
const mono500 = '#9F9F9F';
const mono700 = '#626262';
const mono800 = '#434343';
const mono900 = '#222222';
const grey350 = '#949494';
const grey450 = '#616161';

export default new Vuetify({
	theme: {
		options: { customProperties: true },
		themes: {
			light: {
				primary: '#35B5AF',
				secondary: '#35B5AF',
				accent: colors.blue.lighten1,

				error: '#E74236',
				info: '#21577D',
				success: '#005AAA',
				warning: '#EAA11A',

				badge: '#D74175',

				negative: colors.grey.lighten1,
				neutral: colors.grey.lighten3,
				positive: '#35B5AF',

				background: colors.shades.white,
				componentBG: '#F2F7FB',
				// formTitle: colors.grey.base,
				// formBody: colors.grey.darken4,
				// formPH: '#C4C4C4',

				chipLightBackground1: '#DDDDDD',
				chipLightBackground2: '#F2F2F2',
				chipLightBackground3: '#35B5AF',
				chipDarkBackground1: '#4F4F4F',

				chipDarkText: '#212121',

				fieldLabel: '#9E9E9E',

				offWhite,
				mono50,
				mono200,
				mono300,
				mono500,
				mono700,
				mono800,
				mono900,
				grey350,
				grey450,

				peach,
				kimberly,
			},
		},
	},
	icons: {
		values: globalIcons,
	},
	// lang: {
	// 	t: (key, ...params) => String(i18n.t(key, params)),
	// },
});
