import { Module } from 'vuex';
import { RootState, AlertState } from '../types';

const SET_MESSAGE = 'SET_MESSAGE';
const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const AlertActionKeys = {
	success: 'success',
	error: 'error',
	close: 'close',
};

const store: Module<AlertState, RootState> = {
	namespaced: true,

	state: {
		message: {
			text: '',
			type: 'success',
		},
	},

	actions: {
		[AlertActionKeys.success]({ commit }, text: string) {
			commit(SET_MESSAGE, { text, type: 'success' });
		},

		[AlertActionKeys.error]({ commit }, text: string) {
			commit(SET_MESSAGE, { text, type: 'error' });
		},

		[AlertActionKeys.close]({ commit }) {
			commit(CLEAR_MESSAGE);
		},
	},

	mutations: {
		[SET_MESSAGE](state, { text, type }) {
			state.message = { text, type };
		},

		[CLEAR_MESSAGE](state) {
			state.message = null;
		},
	},

	getters: {
		text: state => state.message?.text,
		type: state => state.message?.type,
	},
};

export default store;
