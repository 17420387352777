import { Module } from 'vuex';
import { api } from '../../plugins/salut-api';
import { District, Region, SubDistrict } from '../../types';
import {
	LocationState,
	outsideHongKongFactory,
	regionOptionAllFactory,
	RootState,
} from '../types';

const SET_REGIONS = 'SET_REGIONS';
const SET_DISTRICTS = 'SET_DISTRICTS';
const SET_SUB_DISTRICTS = 'SET_SUB_DISTRICTS';

export const LocationActionKeys = {
	fetchRegions: 'fetchRegions',
	fetchDistricts: 'fetchDistricts',
	fetchSubDistricts: 'fetchSubDistricts',
	fetchAll: 'fetchAll',
};

export const LocationGetterKeys = {
	regions: 'regions',
	getRegion: 'getRegion',
	districts: 'districts',
	getDistrictsByRegion: 'getDistrictsByRegion',
	getDistrict: 'getDistrict',
	getSubDistrictsByDistrict: 'getSubDistrictsByDistrict',
	getSubDistrict: 'getSubDistrict',
	availableRegions: 'availableRegions',
	availableDistricts: 'availableDistricts',
	availableSubDistricts: 'availableSubDistricts',
};

const initialState: LocationState = {
	regions: [],
	districts: [],
	subDistricts: [],
};

const store: Module<LocationState, RootState> = {
	namespaced: true,

	state: { ...initialState },

	mutations: {
		[SET_REGIONS](state, regions: Region[]) {
			if (!regions) throw new Error('Missing regions');
			state.regions = regions;
		},

		[SET_DISTRICTS](state, districts: District[]) {
			state.districts = districts;
		},

		[SET_SUB_DISTRICTS](state, subDistricts: SubDistrict[]) {
			state.subDistricts = subDistricts;
		},
	},

	actions: {
		async [LocationActionKeys.fetchRegions](
			{ state, commit },
			options: { force: boolean },
		) {
			if (state.regions.length && !options?.force)
				// already loaded. No need to reload as this data is very static
				return;
			const regions = await api.fetchRegions();
			commit(SET_REGIONS, [
				...regions,
				regionOptionAllFactory(),
				outsideHongKongFactory(),
			]);
		},

		async [LocationActionKeys.fetchDistricts](
			{ state, commit },
			options: { force: boolean },
		) {
			if (state.districts.length && !options?.force)
				// already loaded. No need to reload as this data is very static
				return;
			const districts = await api.fetchDistricts();
			commit(SET_DISTRICTS, districts);
		},

		async [LocationActionKeys.fetchSubDistricts](
			{ state, commit },
			options: { force: boolean },
		) {
			if (state.subDistricts.length && !options?.force)
				// already loaded. No need to reload as this data is very static
				return;
			const subDistricts = await api.fetchSubDistricts();
			commit(SET_SUB_DISTRICTS, subDistricts);
		},

		async [LocationActionKeys.fetchAll]({ dispatch }) {
			return Promise.all([
				dispatch(LocationActionKeys.fetchRegions),
				dispatch(LocationActionKeys.fetchDistricts),
				dispatch(LocationActionKeys.fetchSubDistricts),
			]);
		},
	},

	getters: {
		[LocationGetterKeys.regions]: state => state.regions,

		[LocationGetterKeys.getRegion]: state => (regionCode: string) =>
			state.regions.find(region => region.code === regionCode),

		[LocationGetterKeys.districts]: state => state.districts,

		[LocationGetterKeys.getDistrictsByRegion]: state => (regionCode: string) =>
			state.districts.filter(district => district.regionCode === regionCode),

		[LocationGetterKeys.getDistrict]: state =>
			// TODO: districtCode is unique across Hong Kong, but will it still be true if we expand?
			(districtCode: string) => {
				return state.districts.find(district => district.code === districtCode);
			},

		[LocationGetterKeys.getSubDistrictsByDistrict]: state => (
			districtCode: string,
		) =>
			state.subDistricts.filter(
				subDistrict => subDistrict.districtCode === districtCode,
			),

		[LocationGetterKeys.getSubDistrict]: state =>
			// TODO: subDistrictCode is unique across Hong Kong, but will it still be true if we expand?
			(subDistrictCode: string) => {
				return state.subDistricts.find(
					subDistrict => subDistrict.code === subDistrictCode,
				);
			},

		[LocationGetterKeys.availableRegions]: state =>
			state.regions.filter(region => !region.deletedAt),

		[LocationGetterKeys.availableDistricts]: state =>
			state.districts.filter(district => !district.deletedAt),

		[LocationGetterKeys.availableSubDistricts]: state =>
			state.subDistricts.filter(subDistrict => !subDistrict.deletedAt),
	},
};

export default store;
