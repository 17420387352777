import Vue from 'vue';
import { Module } from 'vuex';

import router from '../../router';
import { AuthState, AuthStatus, RootState } from '../types';

//temp add for retrieveing user info
import jwtDecode from 'jwt-decode';
import { api } from '../../plugins/salut-api';
import { LOGOUT_API_PATH, LOGOUT_FORCE_API_PATH } from '../../lib/salut-api';

const SET_STATUS = 'SET_STATUS';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
const SET_SESSION_EXPIRED_DIALOG = 'SET_SESSION_EXPIRED_DIALOG';
const SET_SESSION_EXPIRED_CHECKING_PAUSED =
	'SET_SESSION_EXPIRED_CHECKING_PAUSED';

const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';
const ACCESS_TOKEN_STORAGE_KEY = 'access_token';

export const AuthActionKeys = {
	logout: 'logout',
	logoutForce: 'logoutForce',
	authSuccess: 'authSuccess',
	authError: 'authError',
	getNewToken: 'getNewToken',
	setSessionExpired: 'setSessionExpired',
	setAccessToken: 'setAccessToken',
	unsetAccessToken: 'unsetAccessToken',
	initAccessTokenFromLocalStorage: 'initAccessTokenFromLocalStorage',
	pauseSessionExpiredChecking: 'pauseSessionExpiredChecking', //deprecated
	resumeSessionExpiredChecking: 'resumeSessionExpiredChecking', //deprecated
};

export const AuthGetterKeys = {
	userId: 'userId',
	accessToken: 'accessToken',
	getRefreshToken: 'getRefreshToken',
	isAuthenticated: 'isAuthenticated',
	authStatus: 'authStatus',
	sessionExpiredDialog: 'sessionExpiredDialog',
	sessionExpiredCheckingPaused: 'sessionExpiredCheckingPaused',
	tokenPayload: 'tokenPayload',
	email: 'email',
	roles: 'roles',
	centerId: 'centerId',
	ngoId: 'ngoId',
};

const store: Module<AuthState, RootState> = {
	namespaced: true,

	state: {
		status: null,
		sessionExpiredDialog: false,
		sessionExpiredCheckingPaused: false,
		scheduledRefreshTimeoutHandler: null,
		accessToken: null,
	},

	getters: {
		[AuthGetterKeys.accessToken]: state => state.accessToken || '',
		[AuthGetterKeys.getRefreshToken]: () => () =>
			localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY) || '',
		[AuthGetterKeys.isAuthenticated]: (_, getters) => () =>
			!!getters[AuthGetterKeys.getRefreshToken](),
		[AuthGetterKeys.authStatus]: state => state.status,
		[AuthGetterKeys.sessionExpiredDialog]: state =>
			!state.sessionExpiredCheckingPaused && state.sessionExpiredDialog,
		[AuthGetterKeys.tokenPayload]: state =>
			state.accessToken ? jwtDecode(state.accessToken) : {},
		[AuthGetterKeys.email]: (_, getters) =>
			getters[AuthGetterKeys.tokenPayload].email,
		[AuthGetterKeys.roles]: (_, getters) =>
			getters[AuthGetterKeys.tokenPayload].roles,
		[AuthGetterKeys.centerId]: (_, getters) =>
			getters[AuthGetterKeys.tokenPayload].centerId,
		[AuthGetterKeys.ngoId]: (_, getters) =>
			getters[AuthGetterKeys.tokenPayload].ngoId,
		[AuthGetterKeys.userId]: (_, getters) =>
			getters[AuthGetterKeys.tokenPayload].sub,
	},

	actions: {
		async login({ getters, commit, dispatch }, user) {
			commit(SET_STATUS, AuthStatus.loading);
			// reset dialog state because we're trying a new account / password now
			commit(SET_SESSION_EXPIRED_DIALOG, false);
			try {
				if (getters.isAuthenticated()) {
					// logout first to clear all states of another user
					dispatch(AuthActionKeys.logout);
				}

				const resp = await Vue.axios.post('/admin/login/sc-admin', {
					email: user.email,
					password: user.password,
				});

				if (resp.data.require2fa) {
					return resp.data;
				} else {
					const { accessToken, refreshToken } = resp.data;
					dispatch(AuthActionKeys.authSuccess, { accessToken, refreshToken });
					return accessToken;
				}
			} catch (error) {
				dispatch(AuthActionKeys.authError);
				throw error;
			}
		},
		async verify({ dispatch }, user) {
			try {
				const resp = await Vue.axios.post('/admin/verify', {
					email: user.email,
					password: user.password,
					verificationCode: user.verificationCode,
				});

				const { accessToken, refreshToken } = resp.data;
				dispatch(AuthActionKeys.authSuccess, { accessToken, refreshToken });

				return accessToken;
			} catch (error) {
				dispatch(AuthActionKeys.authError);
				throw error;
			}
		},

		[AuthActionKeys.authSuccess](
			{ commit, dispatch },
			{ accessToken, refreshToken },
		) {
			dispatch(AuthActionKeys.setAccessToken, { accessToken });
			commit(SET_REFRESH_TOKEN, refreshToken);
			commit(SET_STATUS, AuthStatus.success);
		},

		[AuthActionKeys.authError]({ commit, dispatch }) {
			dispatch(AuthActionKeys.unsetAccessToken);
			commit(SET_STATUS, AuthStatus.error);
		},

		async requestResetPassword(_, email) {
			return await api.requestResetPassword(email);
		},

		async resetPassword(
			_,
			data: {
				code: string;
				newPassword: string;
			},
		) {
			await api.resetPassword(data.code, data.newPassword);
			router.push({ path: '/reset-password-success' });
		},

		changePassword: (_, data: { oldPassword: string; newPassword: string }) => {
			return Vue.axios.post(`/admin/password-change/`, {
				oldPassword: data.oldPassword,
				newPassword: data.newPassword,
			});
		},

		[AuthActionKeys.setSessionExpired]({ commit, dispatch }) {
			// clear access token
			dispatch(AuthActionKeys.unsetAccessToken);

			// as session already expired, no need to keep refresh token
			commit(SET_REFRESH_TOKEN);

			// show session expired dialog
			commit(SET_SESSION_EXPIRED_DIALOG, true);
		},

		[AuthActionKeys.logout](
			{ getters, commit, dispatch },
			payload?: { redirect?: boolean },
		) {
			const refreshToken = getters[AuthGetterKeys.getRefreshToken]();
			if (refreshToken) {
				commit(SET_REFRESH_TOKEN);
				// if logout has problem, just assume it's successful and continue
				Vue.axios.post(LOGOUT_API_PATH, {
					refreshToken,
				});
			}
			commit(SET_SESSION_EXPIRED_DIALOG, false);
			dispatch(AuthActionKeys.unsetAccessToken);
			dispatch('resetModuleState', null, { root: true });
			if (payload?.redirect) {
				router.push({ path: '/login' });
			}
		},
		async [AuthActionKeys.logoutForce]({ getters, commit, dispatch }) {
			await Vue.axios.post(LOGOUT_FORCE_API_PATH, {
				refreshToken: getters[AuthGetterKeys.getRefreshToken](),
			});
			commit(SET_SESSION_EXPIRED_DIALOG, false);
			dispatch(AuthActionKeys.unsetAccessToken);
			commit(SET_REFRESH_TOKEN);
			dispatch('resetModuleState', null, { root: true });
			router.push({ path: '/login' });
		},

		async [AuthActionKeys.getNewToken]({ getters, dispatch }) {
			if (!getters[AuthGetterKeys.getRefreshToken]()) {
				console.error('Missing refresh token');
				return dispatch(AuthActionKeys.setSessionExpired);
			}
			const accessToken = await api.getNewAccessToken(
				getters[AuthGetterKeys.getRefreshToken](),
			);

			if (accessToken) {
				await dispatch(AuthActionKeys.setAccessToken, { accessToken });
			} else {
				// handled by axios interceptors already. Don't do anything
			}
			return accessToken;
		},

		[AuthActionKeys.setAccessToken](
			{ state, commit, dispatch },
			{ accessToken }: { accessToken: string },
		) {
			// set to both state and local storage
			localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
			commit(SET_ACCESS_TOKEN, accessToken);

			const { exp } = jwtDecode<{ iat: number; exp: number }>(accessToken);
			if (state.scheduledRefreshTimeoutHandler) {
				clearTimeout(state.scheduledRefreshTimeoutHandler);
			}
			state.scheduledRefreshTimeoutHandler = setTimeout(() => {
				dispatch(AuthActionKeys.getNewToken);
			}, Math.floor(exp - Date.now() / 1e3 - 1) * 1e3);
		},

		[AuthActionKeys.unsetAccessToken]({ state, commit }) {
			// unset in both state and local storage
			localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, '');
			commit(SET_ACCESS_TOKEN, null);

			if (state.scheduledRefreshTimeoutHandler) {
				clearTimeout(state.scheduledRefreshTimeoutHandler);
			}
		},

		[AuthActionKeys.initAccessTokenFromLocalStorage]({ state, commit }) {
			if (state.accessToken) {
				// access token already in Vuex state, no need to init
				return;
			}
			const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
			commit(SET_ACCESS_TOKEN, accessToken);
		},

		[AuthActionKeys.pauseSessionExpiredChecking]({ commit }) {
			commit(SET_SESSION_EXPIRED_CHECKING_PAUSED, true);
		},

		[AuthActionKeys.resumeSessionExpiredChecking]({ commit }) {
			commit(SET_SESSION_EXPIRED_CHECKING_PAUSED, false);
		},
	},

	mutations: {
		[SET_ACCESS_TOKEN]: (state, accessToken: string | null) => {
			state.accessToken = accessToken;
		},
		/**
		 * this is a "fake" mutation that only set localStorage,
		 * but kept for possible backward compatibility
		 */
		[SET_REFRESH_TOKEN]: (_, refreshToken?: string) => {
			refreshToken = refreshToken || '';
			localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
		},
		[SET_STATUS]: (state, status) => {
			state.status = status;
		},
		[SET_SESSION_EXPIRED_DIALOG]: (state, dialogOpen) => {
			state.sessionExpiredDialog = dialogOpen;
		},
		[SET_SESSION_EXPIRED_CHECKING_PAUSED]: (state, paused) => {
			state.sessionExpiredCheckingPaused = paused;
		},
	},
};

export default store;
