export enum RegistrationType {
	taxExempt = 'taxExempt',
	societies = 'societies',
	limitByGuarantee = 'limitByGuarantee',
	limitByShares = 'limitByShares',
}

export enum NgoCategory {
	ngo = 'ngo',
	se = 'se',
	school = 'school',
	university = 'university',
	corporate = 'corporate',
}
